import * as React from "react";
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Copyright from "../common/Copyright";

const CustomContainer = styled(Container)(() => ({
  marginTop: "3em",
}));

export default function DashboardContainer({ containerProps, children }) {
  return (
    <CustomContainer {...containerProps}>
      {children}
      <Copyright />
    </CustomContainer>
  );
}
