import React from 'react';
import {
    Snackbar,
    Skeleton,
    Grid
} from '@mui/material';
import { Alert } from '@mui/material';
import DataSourceRef from '../../common/footnotes/DataSourceRef';
import { Line } from "react-chartjs-2";
import { useRecord } from "../../../api/records";
import palette from '../../../helpers/palette';

const getChartData = (openMonthsData) => {
    let numColours = 1
    let chromaPalette = palette(numColours)
    let datapoint = openMonthsData.data.map(function (obj) {
        return {
            x: obj["Year"] + "-" + obj["Month"],
            y: obj["Count"]
        }
    })

    let set = {
        label: "Number of GLAMs open by month",
        backgroundColor: chromaPalette[0],
        borderColor: chromaPalette[0],
        data: datapoint
    }
    return {
        datasets: [set]
    }
}


const options = {
    plugins: {
        legend: {
            display: true,
        },
    },
    scales: {
        x: {
            stacked: false,
            title: {
                text:"Year and Month",
                display: true
            },
            type: "time",
            time: {
                parser: "YYYY-MM",
                tooltipFormat:"MMM YYYY",
                unit: "month"
            },
            ticks: {
                maxTicksLimit: 20,
                autoSkip: true
            }
        },
        y: {
            stacked: false,
            title: {
                text: "Number of GLAMs Open",
                display: true
            }
        }
    }
};

const OpenMonths = () => {
    const sourceCode = "get_open_months"
    const { data: openMonthsData, isFetching, isError } = useRecord(sourceCode, null);

    return (
        <>
            {isError &&
                <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Something went wrong. Please try reloading the page.
                    </Alert>
                </Snackbar>
            }
            {isFetching &&
                <Skeleton variant="rectangular" height={200} />
            }
            {openMonthsData &&
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Line data={getChartData({ ...openMonthsData[0] })} options={options} />
                        </Grid>
                    </Grid>
                    <DataSourceRef code={sourceCode} />
                </>
            }
        </>
    )
}

export default OpenMonths;

