import React from 'react';

import {
    Snackbar,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Grid
} from '@mui/material';
import { Alert } from '@mui/material';
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import { Line } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from '../../../helpers/dataManipulation';
import palette from '../../../helpers/palette';


const getChartData = (rentData, structure, unit) => {

    let colourInd = 0

    let numColours = getUniqueOptions(rentData.data).length
    let chromaPalette = palette(numColours)

    let datasets = unit.map(unitValue => {
        let data_point = rentData.data
            .filter(obj => obj["Type of structure"] === structure)
            .filter(obj => obj["Type of unit"] === unitValue)
            .map(obj => ({x: obj["REF_DATE"].toString(), y: obj["VALUE"]}))

        let set = {
            label: unitValue,
            backgroundColor: chromaPalette[colourInd],
            borderColor: chromaPalette[colourInd],
            data: data_point
        }

        return set
    })

    // sorting the datasets
    // 1- drawing longer datasets first (to avoid technical issues, but these should all be the same length)
    // 2- drawing datasets in alphabetical order by label
    datasets.sort(function (a, b) {
        return ["Bachelor units", "One bedroom units", "Two bedroom units", "Three bedroom units"].indexOf(a.label) - ["Bachelor units", "One bedroom units", "Two bedroom units", "Three bedroom units"].indexOf(b.label);
    })

    // setting the colours for each dataset AFTER we have sorted them
    datasets.forEach(function (item) {
        item["backgroundColor"] = chromaPalette[colourInd]
        item["borderColor"] = chromaPalette[colourInd]
        colourInd += 1
    })

    return {
        datasets: datasets
    }
}

const options = {
    plugins: {
        legend: {
            display: true,
        },
    },
    scales: {
        y: {
            title: {
              display: true,
              text: "Average Rent in Dollars"
            }
        },
        x: {
            title: {
                text: "Year",
                display: true
            },
            type: 'time',
            time: {
                parser: "YYYY",
                tooltipFormat: 'YYYY',
                unit: 'year',
            }
        },
    }
};

function getUniqueOptions(data) {
    let unique = data
        .map(obj => obj["Type of structure"])
        .filter(onlyUnique)

    return Object.values({ ...unique })
}

const AverageRents = () => {
    const sourcecode = "get_average_rents"
    const { data: rentData, isFetching, isError } = useRecord(sourcecode, null);

    const [structure, setStructure] = React.useState("Row and apartment structures of three units and over");
    const [uniqueStructure, setUniqueStructure] = React.useState([]);

    const [unit, setUnit] = React.useState(["Bachelor units", "One bedroom units", "Two bedroom units", "Three bedroom units"]);

    React.useEffect(() => {
        if (rentData) {
            setUniqueStructure(getUniqueOptions(rentData[0].data));
        }
    }, [rentData])

    let possibleUnits = ["Bachelor units", "One bedroom units", "Two bedroom units", "Three bedroom units"];

    const handleStructureChange = (event) => {
        const {
            target: { value },
        } = event;

        setStructure(
            value,
        );
    }

    const handleUnitChange = (event) => {
        const {
            target: { value },
        } = event;
        setUnit(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    return (
        <>
            {isError &&
                <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Something went wrong. Please try reloading the page.
                    </Alert>
                </Snackbar>
            }
            {isFetching &&
                <Skeleton variant="rectangular" height={200} />
            }
            {rentData &&
                <>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={12} lg={5}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="structure-simple-select-label">Structure</InputLabel>
                                <Select
                                    labelId="structure-simple-select-label"
                                    id="structure-simple-select"
                                    value={structure}
                                    label="structure"
                                    onChange={handleStructureChange}
                                >
                                    {uniqueStructure.sort().map((option) => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="unit-multiple-checkbox-label">Unit</InputLabel>
                                <Select
                                    labelId="unit-multiple-checkbox-label"
                                    id="unit-multiple-checkbox"
                                    multiple
                                    value={unit}
                                    onChange={handleUnitChange}
                                    input={<OutlinedInput label="unit" />}
                                    renderValue={(selected) => selected.sort(function(a, b) {
                                        return possibleUnits.indexOf(a) - possibleUnits.indexOf(b);
                                    }).join(', ')}
                                >
                                    {possibleUnits.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox checked={unit.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Line data={getChartData({ ...rentData[0] }, structure, unit)} options={options} />
                        </Grid>
                    </Grid>
                    <DataSourceRef code={sourcecode} />
                </>
            }
        </>
    )
}

export default AverageRents;
