import Button from '@mui/material/Button';
import VisualizationNarrative from '../typography/VisualizationNarrative';

import React, { useState } from "react";
import { Box } from '@mui/material';
// import "../App.css";

const ReadMore = ({ children }) => {
  const [isReadMore, setIsReadMore] = useState(false);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      {isReadMore && children}
      <Box sx={{ mb: 2 }}>
        <Button onClick={toggleReadMore} variant="text" size="small"> {isReadMore ? "Show less" : "Show more"} </Button>
      </Box>
    </>
  );
};

export default ReadMore;