import * as React from 'react';

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';

import DashboardContainer from '../../layout/DashboardContainer';
import DashboardTitle from '../../typography/DashboardTitle';
import DashboardSubtitle from '../../typography/DashboardSubtitle';
import VisualizationTitle from '../../typography/VisualizationTitle';
import DashboardHeader3 from '../../typography/DashboardHeader3';

import VacancyRates from '../../indicators/housing/Vacancy';
import AverageRents from '../../indicators/housing/AverageRents';
import HousingPrices from '../../indicators/housing/HousingPrices';
import HousingWaitlist from '../../indicators/housing/HousingWaitlist';
import ReadMore from '../../cards/ReadMore';


const Housing = (props) => {

  // Static habitat for humanity build data
  let HFMbuilds = [
    { year: 2002, units: 1 },
    { year: 2004, units: 2 },
    { year: 2006, units: 2 },
    { year: 2007, units: 1 },
    { year: 2009, units: 2 },
    { year: 2010, units: 1 },
    { year: 2011, units: 1 },
    { year: 2013, units: 2 },
    { year: 2014, units: 1 },
    { year: 2016, units: 1 },
    { year: 2017, units: 2 },
    { year: 2018, units: 2 },
    { year: 2019, units: 2 },
    { year: 2021, units: 2 },
    { year: 2022, units: 4 }
  ]

  return (
    <DashboardContainer sx={{ mt: 7 }}>
      <DashboardTitle sx={{ mb: 3 }}>{Housing.title}</DashboardTitle>
      <Typography variant="body1" sx={{ mb: 5 }}>
        This dashboard explores changes in housing rates, vacancies, prices and rentals. There is an additional examination of affordable housing in the city and the efforts of community organizations to meet those needs from pre-pandemic to present.
      </Typography>

      <Grid container spacing={7} sx={{ mb: 7 }}>

        <Grid item xs={12} lg={12}>
          <DashboardHeader3 sx={{ mb: 2 }}>Rental Prices, Vacancies, and Housing Prices</DashboardHeader3>
          <Typography variant="body1" sx={{ mb: 5 }}>
            Kingston’s housing prices and average rent prices are consistent with the common narrative around housing and rental price changes across Canada.
            Over the early and middle phases of the COVID-19 pandemic, rental prices and demand decreased, which in turn increased the housing vacancy rate.
            This is seen in the small peak in 2020 when people were not moving into new apartments very often, due to apprehension on both the tenant and landlord sides.
            Then in 2021, people began moving into new apartments again much more frequently and taking up more of the vacancies.
          </Typography>
          <VisualizationTitle>Vacancy Rates</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            This graph shows the percentage of row and apartment structures (three units and over) that are vacant in the Kingston census metropolitan area.
          </DashboardSubtitle>
          <VacancyRates />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Average Rents</VisualizationTitle>
          <Typography variant="body1" sx={{ mb: 2 }}>
            This graph shows the average rent of studio, one, two, and three-bedroom unit housing styles in the Kingston census metropolitan area. A point to note is that 3-bedroom rental pricing can be erratic rather than
            steady as seen in studio, 1-bedroom, and 2-bedroom rentals. This is reflective of rental trends in other Canadian cities as well.
          </Typography>
          <ReadMore>
            <Typography variant="body1" sx={{ mb: 2 }}>
              A factor contributing to the rising apartment and rental prices in Kingston in particular was housing inflation brought by mid phases of the COVID-19 pandemic - an average increased price of 19.8% - due to the high volume of people moving away from dense cities into smaller and medium-sized cities. For example, individuals and families moved from Toronto and areas in the Greater Toronto Area to Kingston or Belleville. In 2021, Kingston was one of the top 10 cities that people were moving to; pushing the average housing price to rise to roughly $686,000.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Kingston’s housing prices and average rent prices are consistent with the common narrative around housing and rental price changes across Canada. Over the early and middle phases of the COVID-19 pandemic, rental prices and demand decreased, which in turn increased the housing vacancy rate. This is seen in the small peak in 2020 when people were not moving into new apartments very often, due to apprehension on both the tenant and landlord sides. Then in 2021, people began moving into new apartments again much more frequently and taking up more of the vacancies.
            </Typography>
          </ReadMore>
          <AverageRents />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Housing Prices</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            This graph shows the monthly average housing prices for different types of housing in Kingston.
          </DashboardSubtitle>
          <HousingPrices />
        </Grid>

        <Grid item xs={12} lg={12}>
          <DashboardHeader3>Affordable Housing</DashboardHeader3>
          <br />
          <Typography variant="body1" sx={{ mb: 2 }}>
            Social housing aims to fill the gap for low-income individuals and families by providing supportive housing, government funded subsidies, rent-geared-to-income (RGI) housing and housing benefits that are not available to tenants in the private rental housing market. The City of Kingston oversees the social housing system and is responsible for maintaining rent-geared-to-income units and residents who are in receipt of the Portable Housing Benefit (PHB) through agreements with local service providers in the Kingston and Frontenac service area.  Social housing units and PHB benefits are accessed by way of the centralized waiting list (CWL) which is maintained by housing staff.
          </Typography>
          <ReadMore>
            <Typography variant="body1" sx={{ mb: 2 }}>
              There are many factors contributing to the number of people on the active waitlist for affordable housing. These factors include de-skilling, job loss, rising interest rates, overall inflation, and the rapid increase in average home prices across the country. Similar to early COVID-19 pandemic changes noted in rental prices, the number of people on the active waitlist for affordable housing dropped precipitously in 2020 with the emergence of COVID-19 emergency and recovery benefits. As these benefits expired, the numbers rise back up in 2021 and 2022, reflecting people getting back onto the waitlists in order to obtain more affordable housing.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              The centralized waitlist for social housing is generally a chronological waitlist, housing those on the list the longest first, although certain situations are granted priority placements.  The Housing Services Act stipulates that priority ranking must be given to those on the waitlist whose personal safety, or whose family’s safety is at risk because of domestic abuse or human trafficking.  There is also a number of active applications that are already currently housed in social housing units but are looking to transfer for various reasons.
            </Typography>
          </ReadMore >

          <VisualizationTitle>Number of Active Applicants on Central Housing Waitlist (CWL)</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            This graph shows the number of active applicants on the Central Housing Waitlist, and draws from the City of Kingston’s Social Housing Registry Program.
          </DashboardSubtitle>
          <HousingWaitlist />

          <br />
          <br />

          <VisualizationTitle> Habitat for Humanity</VisualizationTitle>
          <br />
          <Typography variant="body1" sx={{ mb: 2 }}>
            This table shows the static data from Habitat for Humanity Kingston since 2002. It is demonstrated that there have been 1 or 2 houses built every year or every other year. Since 2016, there is a consistency in the need for affordable housing through Habitat for Humanity. It is clear that 2020 resulted in postponed housing builds due to the COVID-19 pandemic, and appears to now be a surge in the number of housing builds/needs in Kingston, with 4 houses being built in 2022 – which is 2x to 4x than the per year average Habitat home being built in Kingston previously.
          </Typography>
          <ReadMore>
            <Typography variant="body1" sx={{ mb: 2 }}>
              The need for affordable housing and Habitat for Humanity builds are happening alongside the gentrification of the downtown Kingston core and the building of high-rise apartments in the downtown core, north and west ends. Many of the new downtown core builds work to provide short-term rental options for the university student population growth. Habitat for Humanity Kingston’s data shows that there is some work being done to address and build affordable housing, and help to work on stopping cycles of homelessness. Moreover, it is an alternative pathway option to home ownership.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              However, of note on this affordable housing work is that it is not a solution to homelessness because there are still financial savings requirements as well as the ability of the potential homeowner to put in sweat equity into their future homes. To better understand the social impacts of this data, more information regarding the demographics of people on active affordable housing waitlists and Habitat for Humanity waitlists/applications/ownership is required.
            </Typography>
          </ReadMore>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableBody>

                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                  <TableCell>Year</TableCell>

                  {
                    HFMbuilds.map((build) => (
                      <TableCell align="center" component="th" scope="row">{build.year}</TableCell>
                    ))
                  }

                </TableRow>

                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                  <TableCell>Units Built</TableCell>

                  {
                    HFMbuilds.map((build) => (
                      <TableCell align="center" component="th" scope="row">{build.units}</TableCell>
                    ))
                  }

                </TableRow>

              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <br />


        </Grid>

      </Grid>

    </DashboardContainer>
  )
}

export default Housing;

Housing.path = "/dashboards/Housing"
Housing.title = "Housing"
Housing.description = "This dashboard explores indicators for measuring the availability and cost of housing and examines the impact of the COVID-19 pandemic on living in Kingston."