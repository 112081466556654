import * as React from 'react';
import {
  Typography,
} from '@mui/material';

export default function DashboardTitle(props) {
  let typoProps = {
    variant: 'h3',
    fontWeight: 'bold',
    color: 'primary',
    ...props
  }
  return React.createElement(Typography, typoProps)
}