import React from 'react';
import {
    Snackbar,
    Skeleton,
    Grid
} from '@mui/material';
import { Alert } from '@mui/material';
import DataSourceRef from '../../common/footnotes/DataSourceRef';
import { Bar } from "react-chartjs-2";
import { useRecord } from "../../../api/records";
import palette from '../../../helpers/palette';

const getChartData = (siteClosureData) => {
    let uniqueYears = Object.keys(siteClosureData.data[0])
    uniqueYears.splice(uniqueYears.indexOf("Month"), 1)
    console.log(uniqueYears)
    let colourInd = 0
    let chromaPalette = palette(uniqueYears.length)
    let datasets = uniqueYears.map(year => {
        let datapoint = siteClosureData.data.filter(obj => obj["Month"] !== "total Respondents").map(function (obj) {
            return {
                x: obj["Month"],
                y: obj[year]
            }
        })
        let set = {
            label: year,
            backgroundColor: chromaPalette[colourInd],
            borderColor: chromaPalette[colourInd],
            data: datapoint
        }
        colourInd += 1
        return set
    })
    

    console.log(datasets)
    return {
        datasets: datasets
    }
}


const options = {
    plugins: {
        legend: {
            display: true,
        },
    },
    scales: {
        x: {
            stacked: false,
            title: {
                text:"Months",
                display: true
            }
        },
        y: {
            stacked: false,
            title: {
                text: "Number of Site Closures",
                display: true
            },
            type: 'logarithmic'
        }
    }
};

const SiteClosures = () => {
    const sourceCode = "get_site_closures"
    const { data: siteClosureData, isFetching, isError } = useRecord(sourceCode, null);

    return (
        <>
            {isError &&
                <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Something went wrong. Please try reloading the page.
                    </Alert>
                </Snackbar>
            }
            {isFetching &&
                <Skeleton variant="rectangular" height={200} />
            }
            {siteClosureData &&
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Bar data={getChartData({ ...siteClosureData[0] })} options={options} />
                        </Grid>
                    </Grid>
                    <DataSourceRef code={sourceCode} />
                </>
            }
        </>
    )
}

export default SiteClosures;