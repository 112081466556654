// test
import React from 'react';

import {
    Snackbar,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Grid
} from '@mui/material';
import { Alert } from '@mui/material';
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import { Line } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from '../../../helpers/dataManipulation';
import palette from '../../../helpers/palette';

const getChartData = (employmentSectorsData, sector, startDate) => {
    let chromaPalette = palette(sector.length)
    let colourInd = 0

    let labels = employmentSectorsData.data
        .filter(obj => obj["North American Industry Classification System (NAICS)"] === sector[0])
        .filter(obj => obj["REF_DATE"] >= startDate)
        .map(obj => obj.REF_DATE)
    let datasets = sector.map(sectorValue => {
        let data = employmentSectorsData.data
            .filter(obj => obj["North American Industry Classification System (NAICS)"] === sectorValue)
            .filter(obj => obj["REF_DATE"] >= startDate)
            .map(obj => obj.VALUE)

        let dataset = {
            label: sectorValue,
            data: data
        }

        return dataset
    })

    // sorting the datasets
    // 1- drawing longer datasets first (to avoid technical issues, but these should all be the same length)
    // 2- drawing datasets in alphabetical order by label
    datasets.sort(function (a,b) {
        if (a.data.length < b.data.length) {
            return -1
        } else if (a.data.length > b.data.length) {
            return 1
        } else {
            if (a.label === "Total employed, all industries") {
                return -1
            } else if (b.label === "Total employed, all industries") {
                return 1
            } else {
                if (a.label <= b.label) {
                    return -1
                } else {
                    return 1
                }
            }
        }
    })

    // setting the colours for each dataset AFTER we have sorted them
    datasets.forEach(function (item) {
        item["backgroundColor"] = chromaPalette[colourInd]
        item["borderColor"] = chromaPalette[colourInd]
        colourInd += 1
    })

    let unitOfMeasurement = employmentSectorsData.data
        .filter(obj => obj["North American Industry Classification System (NAICS)"] === sector[0]).map(obj => obj.UOM)[0]
    let factor = employmentSectorsData.data
        .filter(obj => obj["North American Industry Classification System (NAICS)"] === sector[0]).map(obj => obj.SCALAR_FACTOR)[0]

    if (factor === "thousands") {
        options.scales.y.title.text = unitOfMeasurement + ' (x1000)'
    } else if (factor === "units") {
        options.scales.y.title.text = unitOfMeasurement
    } else {
        options.scales.y.title.text = unitOfMeasurement + ' (' + factor + ')'
    }

    return {
        labels: labels,
        datasets: datasets
    }
}

const options = {
    plugins: {
        legend: {
            display: true,
        },
    },
    scales: {
        x: {
            ticks: {
                maxTicksLimit: 20
            },
            type: 'time',
            time: {
                parser: "YYYY-MM",
                tooltipFormat: 'MMM YYYY',
                unit: 'month',
            }
        },
        y: {
            title: {
                text: "DEFAULT",
                display: true
            }
        }
    }
};

function getUniqueSector(data) {
    // filtering out the "total employed" sector and placing it at the top of the list for readability
    // (rather than having it be sorted alphabetically with the rest)
    return ["Total employed, all industries"].concat(data
        .filter(obj => obj["REF_DATE"] >= "2019-01")
        .filter(obj => typeof obj["VALUE"] === "number")
        .filter(obj => obj["North American Industry Classification System (NAICS)"] !== "Total employed, all industries")
        .map((obj) => obj["North American Industry Classification System (NAICS)"])
        .filter(onlyUnique)
        .sort())
}

const EmploymentSectors = (props) => {
    const sourceCode = "get_employment_sectors"
    const { data: employmentSectorsData, isFetching, isError } = useRecord(sourceCode, null);

    const [sector, setSector] = React.useState(["Total employed, all industries"]);
    const [uniqueSector, setUniqueSector] = React.useState([])

    React.useEffect(() => {
        if(employmentSectorsData) {
            setUniqueSector(getUniqueSector(employmentSectorsData[0].data));
        }
    }, [employmentSectorsData])
    
    const handleSectorChange = (event) => {
        const {
            target: { value },
        } = event;
        setSector(
            // On autofill we get the stringified value.
            typeof value === 'string' ? value.split(';') : value,
        );
    };

    return (
        <>
            {isError &&
                <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Something went wrong. Please try reloading the page.
                    </Alert>
                </Snackbar>
            }
            {isFetching &&
                <Skeleton variant="rectangular" height={200} />
            }
            {employmentSectorsData &&
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="sector-multiple-checkbox-label">Sector</InputLabel>
                                <Select
                                    labelId="sector-multiple-checkbox-label"
                                    id="sector-multiple-checkbox"
                                    multiple
                                    value={sector}
                                    onChange={handleSectorChange}
                                    input={<OutlinedInput label="Sector" />}
                                    renderValue={(selected) => selected.sort(function (a, b) {
                                        if (a === "Total employed, all industries")  {
                                            return -1
                                        } else if (b === "Total employed, all industries") {
                                            return 1
                                        } else {
                                            if (a <= b) {
                                                return -1
                                            } else {
                                                return 1
                                            }
                                        }
                                    }).join('; ')}
                                >
                                    {uniqueSector.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox checked={sector.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Line data={getChartData({ ...employmentSectorsData[0] }, sector, props.startDate)} options={options} />
                    <DataSourceRef code={sourceCode} />
                </>
            }
        </>
    )
}

export default EmploymentSectors;

EmploymentSectors.defaultProps = {
    startDate: "2019-01"
}