import React from 'react';
import { GoogleMap, Marker, useJsApiLoader, InfoWindow } from '@react-google-maps/api';
import { useState } from "react";

import {
  Alert,
  Avatar,
  Box,
  Chip,
  Skeleton,
  Snackbar,
  Stack,
} from '@mui/material';

import markerError from '../../../static/icons/map_marker_black_error.png'
import marker2020 from '../../../static/icons/map_marker_red.png'
import marker2021 from '../../../static/icons/map_marker_blue.png'
import marker20202021 from '../../../static/icons/map_marker_green.png'

import { useRecord } from "../../../api/records";
import DataSourceRef from '../../common/footnotes/DataSourceRef';

const formatMarkerData = (temppatiodata) => {
  let formatted = temppatiodata.map(marker => {
    return ({
      Latitude: marker["Latitude"],
      Longitude: marker["Longitude"],
      Title: marker["Business Name"],
      Label: marker["Full Address"],
      Year: marker["Year"],
    })
  }
  )
  return formatted
}

const PatioMap = () => {
  const sourceCode = "get_temp_patio"
  const { data: temppatiodata, isFetching, isError } = useRecord(sourceCode, null)

  const mapStyles = {
    height: "80vh",
    width: "100%",
  }

  const visibilityStyles = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ebe3cd"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#523735"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f1e6"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#c9b2a6"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#dcd2be"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#ae9e90"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#93817c"
        }
      ]
    },
    {
      "featureType": "poi.attraction",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.business",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.business",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.government",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.medical",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.medical",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#a5b076"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#447530"
        }
      ]
    },
    {
      "featureType": "poi.place_of_worship",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.school",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.school",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.sports_complex",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f1e6"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#fdfcf8"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f8c967"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#e9bc62"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e98d58"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#db8555"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#806b63"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8f7d77"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#ebe3cd"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#b9d3c2"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#92998d"
        }
      ]
    }
  ]

  const kingstonCenter = {
    lat: 44.233334, lng: -76.500000
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY
  })

  const initInfoOpen = () => {
    let initState = {}
    const range = [...Array(50).keys()]
    range.forEach(index => {
      initState[index] = false
    })
    return initState
  }
  const [infoOpen, setInfoOpen] = useState(initInfoOpen());

  const handleInfoOpen = (index) => {
    let updatedInfoOpen = { ...infoOpen }

    updatedInfoOpen[index] = !infoOpen[index]

    setInfoOpen(updatedInfoOpen)
  }

  const yearIcon = {
    '2020': marker2020,
    '2021': marker2021,
    '2020, 2021': marker20202021
  }

  function getMarkerIcon(year) {
    year = String(year)
    try {
      return yearIcon[year]
    } catch (error) {
      return markerError
    }
  }

  function getMarkerStyle(year) {
    return new window.google.maps.MarkerImage(
      getMarkerIcon(year),
      null,
      null,
      null
    )
  }

  return isLoaded ? (

    <>
      {isError &&
        <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      }
      {isFetching &&
        <Skeleton variant="rectangular" height={500} />
      }
      {temppatiodata &&

        <>
          <Box
            sx={{
              mb: 1,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Stack direction='row' spacing={0.5}>
              {Object.entries(yearIcon).map(([year, icon], index) => {
                return <Chip
                  key={index}
                  avatar={<Avatar src={icon} imgProps={{ sx: { width: 'auto' } }} />}
                  label={year}
                  variant="outlined"
                />
              })}
            </Stack>
          </Box>
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            center={kingstonCenter}
            options={{ styles: visibilityStyles }}
          >

            {formatMarkerData(temppatiodata[0].data).map((element, index) => {
              return (
                <Marker
                  icon={getMarkerStyle(element.Year)}
                  key={index}
                  position={{ lat: element.Latitude, lng: element.Longitude }}
                  onClick={() => { handleInfoOpen(index) }}
                >

                  {infoOpen[index] && (
                    <InfoWindow
                      onCloseClick={() => {
                        handleInfoOpen(index);
                      }}
                      position={{
                        lat: element.latitude,
                        lng: element.longitude
                      }}
                    >
                      <div>
                        <h3>{element.Title}</h3>
                        <h5>{element.Label}</h5>
                        <p>{element.Year}</p>
                      </div>
                    </InfoWindow>
                  )}

                </Marker>
              )

            })}

          </GoogleMap>
          <DataSourceRef code={sourceCode} />

        </>
      }
    </>
  ) : <></>
}


export default PatioMap;
