import * as React from 'react';
import {
  Typography,
} from '@mui/material';

export default function DashboardHeader3(props) {
  let typoProps = {
    variant: 'h3',
    fontWeight: 'medium',
    color: 'primary',
    fontSize: '2rem',
    ...props
  }
  return React.createElement(Typography, typoProps)
}