import * as React from "react";

import { Container, Typography } from "@mui/material";

import Copyright from "../common/Copyright";
import DashboardCards from "../common/DashboardCards";

const Dashboards = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 7, mb: 4 }}>
      <Typography color="primary" variant="h3" fontWeight="bold" sx={{ mb: 3 }}>
        Dashboards
      </Typography>
      <DashboardCards />
      <Copyright />
    </Container>
  );
};

export default Dashboards;

Dashboards.title = "Dashboards";
Dashboards.path = "/dashboards";
Dashboards.description = "See all available dashboards.";
