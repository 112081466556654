import React from 'react';

import { Skeleton, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

import { Line } from "react-chartjs-2";
import 'chartjs-adapter-moment';

import { useRecord } from "../../../api/records";
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import palette from '../../../helpers/palette';
import chroma from 'chroma-js';


const getChartData = (ridershipdata) => {

  let chromaPalette = palette(1);

  let lighterColour = chroma(chromaPalette[0]).brighten(1.5);

  let data_point = ridershipdata.data.map(obj => ({x: obj["Date"], y: obj["Daily Ridership"]}))

    let set = {
        backgroundColor: chromaPalette[0],
        borderColor: lighterColour,
        tension: 0.1,
        label: "Daily Ridership",
        data: data_point,
    }

    let datasets = [set]

    return {
        datasets: datasets
    }
}

const options = {
    scales: {
      y: {
        title: {
          display: true,
          text: "Daily Ridership"
        }
      },
      x: {
        title: {
          display: true,
          text: "Date"
        },
        type: 'time',
        time: {
          parser: "YYYY-MM-DD",
          tooltipFormat:'MMM DD, YYYY'
        }
      }
    }
};

const Ridership = (props) => {
  const sourceCode = "get_ridership"
  const { data: ridershipdata, isFetching, isError } = useRecord(sourceCode, null)

  return (
    <>
      {isError &&
        <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      }
      {isFetching &&
        <Skeleton variant="rectangular" height={500}/>
      }
      {ridershipdata &&
        <>
          <Line data={getChartData({ ...ridershipdata[0] }, props.industry)} options={options} />
          <DataSourceRef code={sourceCode}/>
        </>
      }
    </>
  )
}

export default Ridership;

Ridership.defaultProps = {
}