import { useQuery } from "react-query";
import api from './api';

export const fetchRecord = async (code=null, id=null) => {
  const { data } = await api.get('records',{
    params: {
      id: id,
      code: code,
    },
  })
  return data;
}

export const useRecord = (code, id) => {
  return useQuery([code, id], () => fetchRecord(code, id));
};