import React from 'react';

import {
  Snackbar,
  Skeleton,
  FormControl,
  //FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid
} from '@mui/material';

import { Alert } from '@mui/material';

import { Bar } from "react-chartjs-2";

import { useRecord } from "../../../api/records";
import DataSourceRef from '../../common/footnotes/DataSourceRef';
import palette from '../../../helpers/palette';

const getChartData = (employerData, sector) => {
  if (sector) employerData.data = employerData.data.filter(obj => obj.Sector === sector)
  employerData.data.sort((a, b) => b.Employees - a.Employees)

  let chromaPalette = palette(employerData.data.map(obj => obj.Name).length)
  chromaPalette.reverse()   // more closely matches the original palette before the switch to central colour control

  let dataset = {
    labels: employerData.data.map(obj => obj.Name),
    datasets: [{
      backgroundColor: chromaPalette,
      borderColor: chromaPalette,
      data: employerData.data.map(obj => obj.Employees)
    }]
  }

  //colourInd += 1
  return dataset
}

const options = {
  indexAxis: 'y',
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    /*
    tooltip: {
      callbacks: {
        label: function (context) {
          return context.formattedValue + ' employees'
        }
      }
    }
    */
  },
  scales: {
    x: {
      title: {
        text: "Number of Employees",
        display: true
      }
    },
    y: {
      title: {
        text: "Employers",
        display: false
      }
    }
  }
};

const TopEmployers = (props) => {
  const sourceCode = "get_top_employers"
  const { data: employerData, isFetching, isError } = useRecord(sourceCode, null)

  const [sector, setSector] = React.useState("public");

  const handleSectorChange = (event) => {
    setSector(event.target.value);
  };

  return (
    <>
      {isError &&
        <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      }
      {isFetching &&
        <Skeleton variant="rectangular" height={200} />
      }
      {employerData &&
        <>
          <Grid container justifyContent="flex-start" spacing={3}>
            <Grid item xs={4} lg={4} >
              <FormControl >
                <RadioGroup
                  row
                  aria-labelledby="sector-radio-buttons-group-label"
                  name="sector-radio-buttons-group"
                  value={sector}
                  onChange={handleSectorChange}
                >
                  <FormControlLabel value="public" control={<Radio size="small" sx={{ padding: '6px' }} />} label="Public Sector" />
                  <FormControlLabel value="private" control={<Radio size="small" sx={{ padding: '6px' }} />} label="Private Sector" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Bar data={getChartData({ ...employerData[0] }, sector)} options={options} />
            </Grid>
          </Grid>
          <DataSourceRef code={sourceCode} />
        </>
      }
    </>
  )
}

export default TopEmployers;