import * as React from 'react';

import './DataDictionary.css'

import { Alert, Container, Skeleton, Snackbar, Grid, Stack, Typography } from '@mui/material';

import SourceCard from '../cards/SourceCard';
import Copyright from '../common/Copyright';

import { useSource } from '../../api/sources';
import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';

function getQueryVariable(variable, query) {
  query = query.replace('?', '')
  let vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log('Query variable %s not found', variable);
}

const DataDictionary = (props) => {
  const { data: sourceData, isFetching, isError } = useSource(null, null)
  let location = useLocation()

  React.useEffect(() => {
    let sourceVal = getQueryVariable('source', location.search || '')
    if (sourceVal) {
      let elem = document.querySelector('#' + sourceVal)
      if (elem) {
        let adjustedPos = elem.getBoundingClientRect().top + window.pageYOffset - 80
        window.scrollTo({
          top: adjustedPos,
          behavior: 'smooth'
        })
        elem.classList.add('highlight-source')
      }
    }
  }, [sourceData, location])

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        overflow: 'auto',
      }}
    >
      <Container sx={{ mt: 7, mb: 4 }}>
        <Typography variant="h3" fontWeight='bold' color='primary'>Data Sources</Typography>
        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 3 }}>Terms of Use</Typography>
        <Typography variant="body1" sx={{ mb: 5 }}>
          This information comes from a variety of sources and is presented to provide users a convenient repository. 
          The creators of this website cannot be held accountable for the veracity of the data.  Users are cautioned 
          to check original sources as provided by this data source page. Below are the data sources used to develop 
          and update the dashboards.
        </Typography>
        {isError &&
          <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
            <Alert severity="error" sx={{ width: '100%' }}>
              Something went wrong. Please try reloading the page.
            </Alert>
          </Snackbar>
        }
        {isFetching &&
          <Stack spacing={5}>
            <Skeleton variant="rectangular" height={250} />
            <Skeleton variant="rectangular" height={250} />
            <Skeleton variant="rectangular" height={250} />
            <Skeleton variant="rectangular" height={250} />
          </Stack>
        }
        {sourceData &&
          <Grid container spacing={3}>
            {sourceData.sort((a, b) => {
              if (a.name < b.name) { return -1; }
              if (a.name > b.name) { return 1; }
              return 0;
            }).map((source, i) =>
              <Grid item xs={12} lg={6} key={i}>
                <SourceCard
                  name={source.name}
                  source={source.source}
                  meta={source.meta}
                  retrieved={source.record_date_modified}
                  id={source.code}
                ></SourceCard>
              </Grid>
            )}
          </Grid>
        }
        <Copyright />
      </Container>
    </Box>
  )
}

export default DataDictionary;

DataDictionary.title = "Data Sources"
DataDictionary.path = "/data-sources"
DataDictionary.description = "Information about all the datasets used in the dashboards."