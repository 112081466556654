import React from 'react';

import {
    Snackbar,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Grid
} from '@mui/material';
import { Alert } from '@mui/material';
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import { Line } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from '../../../helpers/dataManipulation';
import palette from '../../../helpers/palette';

const getChartData = (enrolmentData, schoolBoards, grades) => {
    // academic years (x-axis labels) are sorted in respective collection scripts

    // creating an array of school board + grade combinations we want to graph to simplify mapping
    // (avoiding nested for loops)
    let combinations = []

    for (const board of schoolBoards) {
        for (const grade of grades) {
            combinations.push([board, grade])
        }
    }

    let chromaPalette = palette(combinations.length)
    let colourInd = 0
    
    // combo[0] is the school board and combo[1] is the grade
    let datasets = combinations.map(combo => {
        let data_point = enrolmentData
            .filter(obj => obj["Board Name"] === combo[0])
            .map(function (obj) {
                return {
                    x: obj["Academic Year"],
                    y: obj[combo[1]]
                }
            })

        let set = {
            label: combo[0].concat(", ", combo[1]),
            data: data_point
        }

        return set
    })

    // sorting the datasets
    // 1- drawing longer datasets first (to avoid technical issues, but these should all be the same length)
    // 2- drawing datasets in alphabetical order by label
    datasets.sort(function (a,b) {
        if (a.data.length < b.data.length) {
            return -1
        } else if (a.data.length > b.data.length) {
            return 1
        } else {
            if (a.label <= b.label) {
                return -1
            } else {
                return 1
            }
        }
    })

    // setting the colours for each dataset AFTER we have sorted them
    datasets.forEach(function (item) {
        item["backgroundColor"] = chromaPalette[colourInd]
        item["borderColor"] = chromaPalette[colourInd]
        colourInd += 1
    })

    return {
        datasets: datasets
    }
}

const options = {
    plugins: {
        legend: {
            display: true,
        },
    },
    scales: {
        x: {
            title: {
                text: "Academic Year",
                display: true
            }
        },
        y: {
            title: {
                text: "Number of Students",
                display: true
            }
        }
    }
};

function combineEnrolment(elementary, secondary) {
    let enrolmentData = []
    let i = 0

    for (i = 0; i < elementary.data.length; i++) {
        let entry = elementary.data[i]
        let match = secondary.data
            .filter(obj => obj["Board Number"] === entry["Board Number"])
            .filter(obj => obj["Academic Year"] === entry["Academic Year"])
        enrolmentData.push(Object.assign({}, entry, match[0]))
    }

    // add a grand total of enrolment to each entry
    enrolmentData.forEach((obj) => {
        obj["Total Enrolment"] = obj["Total Elementary Enrolment"] + obj["Total Secondary Enrolment"]
    })

    return enrolmentData
}

function sortGrades(grades) {
    // sorting in an intuitive (chronological) order
    let sortedGrades = []
    let i = 0
    grades.sort()   // to help our reordering

    // kindergarten should go first...
    for (i = 0; i < grades.length; i++) {
        if (grades[i].search("Kindergarten") !== -1) {
            sortedGrades.push(grades[i])
        }
    }

    // then the single-digit grades...
    for (i = 0; i < grades.length; i++) {
        if (grades[i].search(/\D\d\D/g) !== -1) {
            sortedGrades.push(grades[i])
        }
    }

    // then the double-digit grades...
    for (i = 0; i < grades.length; i++) {
        if (grades[i].search(/\d{2}/g) !== -1) {
            sortedGrades.push(grades[i])
        }
    }

    // then the subtotals...
    for (i = 0; i < grades.length; i++) {
        if (grades[i].search(/Total\s\S+\s\S+/g) !== -1) {
            sortedGrades.push(grades[i])
        }
    }

    // then finally the grand total
    for (i = 0; i < grades.length; i++) {
        if (grades[i].search("Total Enrolment") !== -1) {
            sortedGrades.push(grades[i])
        }
    }

    return sortedGrades
}

function getUniqueSchoolBoard(data) {
    return data.map(obj => obj["Board Name"])
    .filter(onlyUnique)
    .sort()
}

function getUniqueGrade(data) {
    // filtering out fields we don't want to include as multi-select options
    let grades = Object.keys(data[0])
        .filter(grade => grade !== "Board Name")
        .filter(grade => grade !== "Board Number")
        .filter(grade => grade !== "Academic Year")

    // need to sort grades in chronological order (rather than alphabetical) before returning
    return sortGrades(grades)
}

const ElementarySecondaryEnrolment = () => {
    const sourceCodeElementary = "get_enrolment_elementary"
    const sourceCodeSecondary = "get_enrolment_secondary"
    const { data: elementaryData, isFetching: isFetchingElementary, isError: isErrorElementary } = useRecord(sourceCodeElementary, null);
    const { data: secondaryData, isFetching: isFetchingSecondary, isError: isErrorSecondary } = useRecord(sourceCodeSecondary, null);

    const [schoolBoard, setSchoolBoard] = React.useState(["Algonquin and Lakeshore CDSB", "Limestone DSB"]);
    const [uniqueSchoolBoard, setUniqueSchoolBoard] = React.useState([])

    const [grade, setGrade] = React.useState(["Total Elementary Enrolment", "Total Secondary Enrolment"]);
    const [uniqueGrade, setUniqueGrade] = React.useState([])

    React.useEffect(() => {
        if(elementaryData && secondaryData) {
            setUniqueSchoolBoard(getUniqueSchoolBoard(combineEnrolment({ ...elementaryData[0] }, { ...secondaryData[0] })))
        }
    }, [elementaryData, secondaryData])

    React.useEffect(() => {
        if(elementaryData && secondaryData) {
            setUniqueGrade(getUniqueGrade(combineEnrolment({ ...elementaryData[0] }, { ...secondaryData[0] })))
        }
    }, [elementaryData, secondaryData])
    
    const handleSchoolBoardChange = (event) => {
        const {
            target: { value },
        } = event;
        setSchoolBoard(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleGradeChange = (event) => {
        const {
            target: { value },
        } = event;
        setGrade(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <>
            {isErrorElementary && isErrorSecondary &&
                <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Something went wrong. Please try reloading the page.
                    </Alert>
                </Snackbar>
            }
            {isFetchingElementary && isFetchingSecondary &&
                <Skeleton variant="rectangular" height={200} />
            }
            {elementaryData && secondaryData &&
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="school-board-multiple-checkbox-label">School Board</InputLabel>
                                <Select
                                    labelId="school-board-multiple-checkbox-label"
                                    id="school-board-multiple-checkbox"
                                    multiple
                                    value={schoolBoard}
                                    onChange={handleSchoolBoardChange}
                                    input={<OutlinedInput label="School Board" />}
                                    renderValue={(selected) => selected.sort().join(', ')}
                                >
                                    {uniqueSchoolBoard.sort().map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox checked={schoolBoard.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="grade-multiple-checkbox-label">Grade</InputLabel>
                                <Select
                                    labelId="grade-multiple-checkbox-label"
                                    id="grade-multiple-checkbox"
                                    multiple
                                    value={grade}
                                    onChange={handleGradeChange}
                                    input={<OutlinedInput label="Grade" />}
                                    renderValue={(selected) => sortGrades(selected).join(', ')}
                                >
                                    {sortGrades(uniqueGrade).map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox checked={grade.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Line data={getChartData(combineEnrolment({ ...elementaryData[0] }, { ...secondaryData[0] }), schoolBoard, grade)} options={options} />
                    <DataSourceRef code={sourceCodeElementary} />
                    <DataSourceRef code={sourceCodeSecondary} />
                </>
            }
        </>
    )
}

export default ElementarySecondaryEnrolment;
