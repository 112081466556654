import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

export default function MediaCard(props) {
  return (
    <Card 
      sx={{
        ...props.CardBackground,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '30rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end'
      }}
      {...props.Card}
    >
      <Box sx={{
        backgroundColor: 'rgba(255,255,255,0.85)',
        // background: 'linear-gradient(0deg, rgba(255,255,255,0.9) 80%, rgba(255,255,255,0) 100%)',
        // pt: '3rem'
      }}>
        <CardContent sx={{ height: '11rem', overflow: 'auto' }}>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2">
            {props.description}
          </Typography>
        </CardContent>
        {props.children}
      </Box>
    </Card>
  );
}

MediaCard.defaultProps = {
  title: "Title",
  description: "Description",
  CardBackground: {},
  Card: {}
}