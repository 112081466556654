import React from 'react';

import { Skeleton, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

import { Line } from "react-chartjs-2";

import 'chartjs-adapter-moment';

import { useRecord } from "../../../api/records";
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import palette from '../../../helpers/palette';


const getChartData = (vacancydata) => {

    let chromaPalette = palette(1)

    let data_point = vacancydata.data.map(obj => ({x: obj["REF_DATE"].toString(), y: obj["VALUE"]}))

    let set = {
        backgroundColor: chromaPalette[0],
        borderColor: chromaPalette[0],
        tension: 0.1,
        label: 'Vacancy Rate',
        data: data_point,
    }

    let datasets = [set]

    return {
        datasets: datasets
      }
}

const options = {
    scales: {
      y: {
        title: {
          display: true,
          text: "Vacancy Rate (%)"
        }
      },
      x: {
        title: {
          display: true,
          text: "Year"
        }
      }
    }
};

const VacancyRates = (props) => {
  const sourceCode = "get_vacancy_rates"
  const { data: vacancydata, isFetching, isError } = useRecord(sourceCode, null)

  return (
    <>
      {isError &&
        <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      }
      {isFetching &&
        <Skeleton variant="rectangular" height={500}/>
      }
      {vacancydata &&
        <>
          <Line data={getChartData({ ...vacancydata[0] }, props.sector)} options={options} />
          <DataSourceRef code={sourceCode}/>
        </>
      }
    </>
  )
}

export default VacancyRates;

VacancyRates.defaultProps = {
}