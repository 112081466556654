import React from 'react';

import { Bar } from "react-chartjs-2";

import {
  Backdrop,
  Box,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';

import { useRecord } from "../../../api/records";
import DataSourceRef from '../../common/footnotes/DataSourceRef';
import palette from '../../../helpers/palette';


const getOccupancyRate = (hotelData) => {
  return {
    labels: hotelData.data.map(obj => obj.Year),
    datasets: [{
      backgroundColor: palette(1)[0],
      data: hotelData.data.map(obj => (Number(obj[" Occupancy Rate"]) * 100).toFixed(2)),
    }]
  }

}

const getDailyRate = (hotelData) => {
  return {
    labels: hotelData.data.map(obj => obj.Year),
    datasets: [{
      backgroundColor: palette(1)[0],
      data: hotelData.data.map(obj => obj[" Daily Rate"]),
    }]
  }

}

const getRevenueRate = (hotelData) => {
  let label = hotelData.data.map(obj => obj.Year)
  return {
    labels: label,
    datasets: [{
      backgroundColor: palette(1)[0],
      data: hotelData.data.map(obj => obj[" Revenue per Available Room"]),
    }]

  }

}

const optionsRevenueRate = {
  indexAxis: 'x',
  scales: {
    x: {
      title: {
        display: true,
        text: 'Year'
      }
    },
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          return '$' + value
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Revenue Rate per Available Room ($)"
    },
  },
  maintainAspectRatio: false
};

const optionsDailyRate = {
  indexAxis: 'x',
  scales: {
    x: {
      title: {
        display: true,
        text: 'Year'
      }
    },
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          return '$' + value
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Average Daily Rate ($)"
    },
  },
  maintainAspectRatio: false
};

const optionsOccupancyRate = {
  indexAxis: 'x',
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Occupancy Rate (%)"
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || '';

          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y + '%';
          }
          return label;
        }
      }
    }
  },
  scales: {
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          return value + '%';
        }
      }
    },
    x: {
      title: {
        display: true,
        text: 'Year'
      }
    }
  },
  maintainAspectRatio: false
};


const HotelOccupancy = (props) => {
  const sourceCode = "get_hotel"
  const { data: hotelData, isFetching, isError } = useRecord(sourceCode, null)
  console.log(hotelData)

  return (
    <>
      {isError &&
        <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      }
      {isFetching &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      {hotelData &&
        <>
          <Box sx={{ minHeight: '30em' }}>
            <Bar data={getOccupancyRate({ ...hotelData[0] })} options={optionsOccupancyRate} />
          </Box>
          <Box sx={{ minHeight: '30em' }}>
            <Bar data={getDailyRate({ ...hotelData[0] })} options={optionsDailyRate} />
          </Box>
          <Box sx={{ minHeight: '30em' }}>
            <Bar data={getRevenueRate({ ...hotelData[0] })} options={optionsRevenueRate} />
          </Box>
          <DataSourceRef code={sourceCode} />
        </>
      }
    </>
  )
}


export default HotelOccupancy;