import React from 'react';

import {
    Snackbar,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Grid
} from '@mui/material';
import { Alert } from '@mui/material';
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import { Line } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from '../../../helpers/dataManipulation';
import palette from '../../../helpers/palette';

const getChartData = (labourForceData, groupBy, descriptor, characteristic, startDate) => {
    let chromaPalette = []
    let colourInd = 0
    let ungrouped = ""
    let defaultDescriptor = ""

    let labels = labourForceData.data
        .filter(obj => obj["Labour force characteristics"] === characteristic)
        .filter(obj => obj["Sex"] === "Both sexes")
        .filter(obj => obj["Age group"] === "15 years and over")
        .filter(obj => obj["REF_DATE"] >= startDate)
        .map(obj => obj.REF_DATE)

    if (groupBy === "Sex") {
        ungrouped = "Age group"
        defaultDescriptor = "15 years and over"
        chromaPalette = palette(labourForceData.data
            .map(obj => obj["Sex"])
            .filter(onlyUnique)
            .length
        )
    } else {
        ungrouped = "Sex"
        defaultDescriptor = "Both sexes"
        chromaPalette = palette(labourForceData.data
            .map(obj => obj["Age group"])
            .filter(onlyUnique)
            .length
        )
    }

    let datasets = descriptor.map(descriptorValue => {
        let data = labourForceData.data
            .filter(obj => obj["Labour force characteristics"] === characteristic)
            .filter(obj => obj[groupBy] === descriptorValue)
            .filter(obj => obj[ungrouped] === defaultDescriptor)
            .filter(obj => obj["REF_DATE"] >= startDate)
            .map(obj => obj.VALUE)

        let set = {
            label: descriptorValue,
            backgroundColor: chromaPalette[colourInd],
            borderColor: chromaPalette[colourInd],
            data: data
        }

        colourInd += 1
        return set
    })

    let unitOfMeasurement = labourForceData.data
        .filter(obj => obj["Labour force characteristics"] === characteristic).map(obj => obj.UOM)[0]
    let factor = labourForceData.data
        .filter(obj => obj["Labour force characteristics"] === characteristic).map(obj => obj.SCALAR_FACTOR)[0]

    if (factor === "thousands") {
        options.scales.y.title.text = unitOfMeasurement + ' (x1000)'
    } else if (factor === "units") {
        options.scales.y.title.text = unitOfMeasurement
    } else {
        options.scales.y.title.text = unitOfMeasurement + ' (' + factor + ')'
    }

    if (unitOfMeasurement === "Percentage") {
        options.scales.y.ticks = {
            callback: function (value) {
                return value + "%"
            }
        }
        options.plugins.tooltip = {
            callbacks: {
                label: function (context) {
                    return context.dataset.label + ': ' + context.formattedValue + '%'
                }
            }
        }
    } else {
        options.scales.y.ticks = {
            callback: function (value) {
                return value
            }
        }
        options.plugins.tooltip = {
            callbacks: {
                label: function (context) {
                    return context.dataset.label + ': ' + context.formattedValue
                }
            }
        }
    }

    return {
        labels: labels,
        datasets: datasets
    }
}

const options = {
    plugins: {
        legend: {
            display: true,
        },
    },
    scales: {
        x: {
            ticks: {
                maxTicksLimit: 20
            },
            stacked: true,
            type: 'time',
            time: {
                parser: "YYYY-MM",
                tooltipFormat: 'MMM YYYY',
                unit: 'month',
            }
        },
        y: {
            stacked: false,
            title: {
                text: "test",
                display: true
            }
        }
    }
};

function getUniqueGroupBy() {
    return ["Age group", "Sex"]
}

function getUniqueDescriptor(data, groupBy) {
    if (groupBy === "Sex") {
        return data
            .map(obj => obj[groupBy])
            .filter(onlyUnique)
            .filter(obj => obj !== "Both sexes")    // currently ignoring "Both sexes" as it doesn't really add information
            .sort()
    }

    else {
        let topOfList = data
            .map(obj => obj[groupBy])
            .filter(onlyUnique)
            .filter(obj => obj.includes("and over"))
            .sort()
        let bottomOfList = data
            .map(obj => obj[groupBy])
            .filter(onlyUnique)
            .filter(obj => obj.includes("to"))
            .sort()

        return topOfList.concat(bottomOfList)
    }
}

function getUniqueCharacteristic(data) {
    return data
        .map(obj => obj["Labour force characteristics"])
        .filter(onlyUnique)
}

const LabourForce = (props) => {
    const sourceCode = "get_labour_force"
    const { data: labourForceData, isFetching, isError } = useRecord(sourceCode, null);

    const [groupBy, setGroupBy] = React.useState("Sex");
    const [uniqueGroupBy, setUniqueGroupBy] = React.useState([])    // not being fetched dynamically right now

    const [descriptor, setDescriptor] = React.useState(["Females", "Males"]);
    const [uniqueDescriptor, setUniqueDescriptor] = React.useState([])

    const [characteristic, setCharacteristic] = React.useState("Population");
    const [uniqueCharacteristic, setUniqueCharacteristic] = React.useState([])

    React.useEffect(() => {
        if (labourForceData) {
            setUniqueGroupBy(getUniqueGroupBy());
        }
    }, [labourForceData])

    React.useEffect(() => {
        if (labourForceData && labourForceData.length > 0) {
            let descriptors = getUniqueDescriptor(labourForceData[0].data, groupBy);
            setUniqueDescriptor(descriptors);
            setDescriptor(descriptors);
        }
    }, [labourForceData, groupBy])

    React.useEffect(() => {
        if (labourForceData && labourForceData.length > 0) {
            setUniqueCharacteristic(getUniqueCharacteristic(labourForceData[0].data));
        }
    }, [labourForceData])

    const handleGroupByChange = (event) => {
        const {
            target: { value },
        } = event;

        setGroupBy(
            value,
        );
    };

    const handleDescriptorChange = (event) => {
        const {
            target: { value },
        } = event;
        setDescriptor(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleCharacteristicChange = (event) => {
        const {
            target: { value },
        } = event;

        setCharacteristic(
            value,
        );
    };

    return (
        <>
            {isError &&
                <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Something went wrong. Please try reloading the page.
                    </Alert>
                </Snackbar>
            }
            {isFetching &&
                <Skeleton variant="rectangular" height={200} />
            }
            {labourForceData &&
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="group-by-simple-select-label">Group By...</InputLabel>
                                <Select
                                    labelId="group-by-simple-select-label"
                                    id="group-by-simple-select"
                                    value={groupBy}
                                    label="Group By..."
                                    onChange={handleGroupByChange}
                                >
                                    {uniqueGroupBy.sort().map((option) => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="descriptor-multiple-checkbox-label">Descriptor</InputLabel>
                                <Select
                                    labelId="descriptor-multiple-checkbox-label"
                                    id="descriptor-multiple-checkbox"
                                    multiple
                                    value={descriptor}
                                    onChange={handleDescriptorChange}
                                    input={<OutlinedInput label="Descriptor" />}
                                    renderValue={(selected) => 
                                        groupBy === "Sex" ? selected.sort().join(', ') : selected.join(', ')
                                    }
                                >
                                    {uniqueDescriptor.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox checked={descriptor.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="characteristic-simple-select-label">Characteristic</InputLabel>
                                <Select
                                    labelId="characteristic-simple-select-label"
                                    id="characteristic-simple-select"
                                    value={characteristic}
                                    label="Characteristic"
                                    onChange={handleCharacteristicChange}
                                >
                                    {uniqueCharacteristic.map((option) => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            {labourForceData.length > 0 &&
                                <Line data={getChartData({ ...labourForceData[0] }, groupBy, descriptor, characteristic, props.startDate)} options={options} />
                            }
                        </Grid>
                    </Grid>
                    <DataSourceRef code={sourceCode} />
                </>
            }
        </>
    )
}

export default LabourForce;

LabourForce.defaultProps = {
    startDate: "2019-01",
}