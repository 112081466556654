import * as React from 'react';

import {
  Grid,
  Typography
} from '@mui/material';

import DashboardContainer from '../../layout/DashboardContainer';
import DashboardTitle from '../../typography/DashboardTitle';
import VisualizationTitle from '../../typography/VisualizationTitle';
import VisualizationNarrative from '../../typography/VisualizationNarrative';

import TopEmployers from '../../indicators/employment/TopEmployers';
import LabourForce from '../../indicators/employment/LabourForce';
import TaxFilers from '../../indicators/employment/TaxFilers';
import EmploymentSectors from '../../indicators/employment/EmploymentSectors';

const Employment = (props) => {
  return (
    <DashboardContainer sx={{ mt: 7 }}>
      <DashboardTitle sx={{ mb: 3 }}>{Employment.title}</DashboardTitle>
      <Typography variant="body1" sx={{ mb: 5 }}>
        This interactive dashboard delves into the impact of the COVID-19 pandemic on the Kingston labour market using indicators such as employment numbers across different sectors and the demographic composition and participation rate of the local labour force.
      </Typography>

      <Grid container spacing={7} sx={{ mb: 7 }}>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Largest Employers</VisualizationTitle>
          <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
            This graph identifies the largest public and private sector employers in Kingston by number of employees.  A significant percentage of Kingston’s labour force works in the public sector, primarily in government, education, and health while the largest private employers are found in manufacturing, insurance, and food services. Employment numbers are based on full-time equivalents and include both onsite and offsite employees.
          </VisualizationNarrative>
          <TopEmployers />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Labour Force Characteristics</VisualizationTitle>
          <VisualizationNarrative sx={{ mt: 1 }}>
            This interactive table uses labour force data on employed and unemployed persons as well as persons not in the labor force to gauge variables impacting employment outcomes based on age and sex.
          </VisualizationNarrative>
          <VisualizationNarrative sx={{ mt: 1 }}>
            The <b>employment rate</b> is the number of employed persons as a percentage of the population aged 15 and older. The <b>unemployment rate</b> is the number of unemployed persons as a percentage of the labour force (employed and unemployed). The unemployment rate for a particular group is determined by the number of unemployed persons as a percentage of the labour force for that group. Persons of working age (15+) who are neither employed nor unemployed are considered <b>not in the labor force</b>. This category includes retired persons, students, those taking care of family, and others who are neither working nor seeking work.
          </VisualizationNarrative>
          <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
            The <b>participation rate</b> is the number of employed and unemployed persons as a percentage of the population aged 15 and older. <b>Full-time employment</b> consists of persons who usually work 30+ hours per week at their main job. <b>Part-time employment</b> consists of persons who usually work less than 30 hours per week at their main job. The participation rate for a particular group (for example, youths aged 15 to 24) is the number employed as a percentage of the population for that group.
          </VisualizationNarrative>
          <LabourForce />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Tax Filer Characteristics</VisualizationTitle>
          <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
            This graph offers insight into the summary characteristics of Kingstonian tax filers (preliminary T1 Family File) by age and income. The median income values are calculated by taking the “middle” value, the value for which half of the observations are larger and half are smaller. Median income is a more robust and accurate measure than average income since it is not affected by the relatively small number of extremely high- and low-income earners.
          </VisualizationNarrative>
          <TaxFilers />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Employment by Sector</VisualizationTitle>
          <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
            This interactive graph enables users to explore employment by industry in Kingston. A total of 15 industry classifications 
            are tracked and can be viewed individually or together.
          </VisualizationNarrative>
          <EmploymentSectors />
        </Grid>

      </Grid>
    </DashboardContainer >
  )
}

export default Employment;

Employment.path = "/dashboards/employment"
Employment.title = "Employment"
Employment.description = "This dashboard explores indicators for local employment and examines the impact of the COVID-19 pandemic on working in Kingston."