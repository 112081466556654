import * as React from "react";

import "./Home.css";

import { Box, Container, Typography, IconButton } from "@mui/material";

import { KeyboardDoubleArrowDown } from "@mui/icons-material";

import Copyright from "../common/Copyright";
import PartnerLogos from "../cards/PartnerLogos";
import imgKingstonAerial from "../../static/img/kingston_aerial.jpg";
import DashboardCards from "../common/DashboardCards";
import RespectImg from "../../static/img/indigenous_artwork/respect_portia_chapman.jpg";
import ImageAttribute from "../typography/ImageAttribute";
import LandAcknowledgement from "../common/LandAcknowledgement";

const Home = () => {
  const dashContEl = React.useRef(null);

  const goToDashboards = () => {
    const yOffset = -50;
    const y =
      dashContEl.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <>
      {/* Box for image background */}
      <Box sx={{ position: "relative" }}>
        <Box
          component="img"
          sx={{
            objectFit: "cover",
            objectPosition: "30% 60%",
            height: "calc(100vh - 55px)",
            width: "100%",
          }}
          alt="The house from the offer."
          src={imgKingstonAerial}
        />
        {/* Box for card */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "calc(100vh - 100px)",
            display: "flex",
            alignItems: "end",
          }}
        >
          <Container maxWidth="md">
            <Box
              color="primary.contrastText"
              sx={{ backgroundColor: "primary.main", p: 5, borderRadius: 2 }}
            >
              <Box
                sx={{
                  mb: 2,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h3">Kingston</Typography>
                  <Typography variant="h2" fontWeight="bold" sx={{ mb: 3 }}>
                    IN Focus
                  </Typography>
                  <Typography sx={{ mb: 2 }}>
                    A data story of how Kingston fared during the pandemic and
                    what that means for building our resilient future.
                  </Typography>
                </Box>

                <Box
                  sx={{ flex: 1, display: "flex", flexDirection: "column" }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    className="center"
                    src={RespectImg}
                    width="210"
                    alt="Respect"
                  />
                  <Box>
                    {/* <ImageAttribute sx={{ mb: 2, fontSize: 10 }}>
                      Artwork by Portia Chapman, used with permission from the Queen's University Office of Indigenous Initiatives.
                    </ImageAttribute> */}
                  </Box>
                </Box>
              </Box>

              <Typography align="center">
                <IconButton onClick={goToDashboards}>
                  <KeyboardDoubleArrowDown
                    fontSize="large"
                    sx={{
                      animation: "pulse 2s infinite",
                      color: "primary.contrastText",
                    }}
                  />
                </IconButton>
              </Typography>
            </Box>
            <ImageAttribute
              color="white"
              align="right"
              sx={{ mb: 2, fontSize: 11 }}
            >
              Artwork by Portia Chapman, used with permission from the Queen's
              University Office of Indigenous Initiatives.
            </ImageAttribute>
          </Container>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: (theme) => theme.palette.grey[100] }}>
        <Container ref={dashContEl} maxWidth="lg">
          <LandAcknowledgement />
          <PartnerLogos />
          <Typography sx={{ mb: 3 }}>
            Kingston In Focus is a collaborative project between Queen’s
            University, the City of Kingston, Kingston Economic Development and
            the Kingston and Area Association of Museums, Art Galleries and
            Historic Sites. This work was supported by Mitacs through the Mitacs
            Business Strategy Internship. This Dashboard draws on research
            supported by the Social Sciences and Humanities Research Council
            Insight Grant 435-2019-0022.
          </Typography>
          <Typography
            color="primary"
            variant="h3"
            fontWeight="bold"
            sx={{ mb: 3 }}
          >
            Dashboards
          </Typography>
          <DashboardCards />
          <Copyright />
        </Container>
      </Box>
    </>
  );
};

export default Home;

Home.title = "Home";
Home.path = "/";
Home.description = "Welcome";
