import React from 'react';

import {
    Snackbar,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Grid
} from '@mui/material';
import { Alert } from '@mui/material';
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import { Bar } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from '../../../helpers/dataManipulation';
import palette from '../../../helpers/palette';

const getChartData = (broadAgeData, ageGroup) => {
    let chromaPalette = palette(ageGroup.length)
    let colourInd = 0

    let datasets = ageGroup.map(ageGroupValue => {
        let data_point = broadAgeData.data
            .filter(obj => obj["Age group"] === ageGroupValue)
            .map(function (obj) {
                return {
                    y: obj["Value"],
                    x: obj["Year"].toString()
                }
            })

        let set = {
            label: ageGroupValue,
            data: data_point
        }

        return set
    })

    // sorting the datasets
    // 1- drawing longer datasets first (to avoid technical issues, but these should all be the same length)
    // 2- drawing datasets in alphabetical order by label
    datasets.sort(function (a,b) {
        if (a.data.length < b.data.length) {
            return -1
        } else if (a.data.length > b.data.length) {
            return 1
        } else {
            if (a.label <= b.label) {
                return -1
            } else {
                return 1
            }
        }
    })

    // setting the colours for each dataset AFTER we have sorted them
    datasets.forEach(function (item) {
        item["backgroundColor"] = chromaPalette[colourInd]
        item["borderColor"] = chromaPalette[colourInd]
        colourInd += 1
    })

    // avoiding cutting off bars
    options.scales.x.ticks.maxTicksLimit = broadAgeData.data
        .map((obj) => obj["Year"])
        .filter(onlyUnique)
        .length

    return {
        datasets: datasets
    }
}

const options = {
    plugins: {
        legend: {
            display: true
        },
    },
    scales: {
        x: {
            ticks: {
                maxTicksLimit: 10
            },
            title: {
                text: "Year",
                display: true
            },
        },
        y: {
            title: {
                text: "Number of People",
                display: true
            }
        }
    }
};

function getUniqueAgeGroup(data) {
    return data
        .map((obj) => obj["Age group"])
        .filter(onlyUnique)
        .sort()
}

const BroadAgeGroups = (props) => {
    const sourceCode = "get_broad_age_groups"
    const { data: broadAgeData, isFetching, isError } = useRecord(sourceCode, null);

    //const [ageGroup, setAgeGroup] = React.useState(['Total Population']);
    const [ageGroup, setAgeGroup] = React.useState(['0-14', '15-29', '30-44', '45-64', '65+']);
    const [uniqueAgeGroup, setUniqueAgeGroup] = React.useState([])

    React.useEffect(() => {
        if(broadAgeData) {
            setUniqueAgeGroup(getUniqueAgeGroup(broadAgeData[0].data));
        }
    }, [broadAgeData])
    
    const handleAgeGroupChange = (event) => {
        const {
            target: { value },
        } = event;
        setAgeGroup(
            // On autofill we get the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <>
            {isError &&
                <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Something went wrong. Please try reloading the page.
                    </Alert>
                </Snackbar>
            }
            {isFetching &&
                <Skeleton variant="rectangular" height={200} />
            }
            {broadAgeData &&
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="age-group-multiple-checkbox-label">Age Group</InputLabel>
                                <Select
                                    labelId="age-group-multiple-checkbox-label"
                                    id="age-group-multiple-checkbox"
                                    multiple
                                    value={ageGroup}
                                    onChange={handleAgeGroupChange}
                                    input={<OutlinedInput label="Age Group" />}
                                    renderValue={(selected) => selected.sort().join(', ')}
                                >
                                    {uniqueAgeGroup.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox checked={ageGroup.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Bar data={getChartData({ ...broadAgeData[0] }, ageGroup)} options={options} />
                    <DataSourceRef code={sourceCode} />
                </>
            }
        </>
    )
}

export default BroadAgeGroups;

BroadAgeGroups.defaultProps = {
    startDate: "2019-01"
}