import * as React from "react";

import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import EventIcon from "@mui/icons-material/Event";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";

import Dashboards from "../../pages/Dashboards";
import DataDictionary from "../../pages/DataDictionary";
import DashboardsMenu from "./DashboardMenu";
import Timeline from "../../pages/Timeline";
import Home from "../../pages/Home";
import Art from "../../pages/Art";

export default function MainMenu() {
  return (
    <>
      <ListItemButton component="a" href={Home.path}>
        <ListItemIcon>
          <HomeIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton component="a" href={Dashboards.path}>
        <ListItemIcon>
          <DashboardIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={Dashboards.title} />
      </ListItemButton>
      <List component="div" disablePadding>
        <DashboardsMenu ListItemButton={{ sx: { pl: 4 } }} />
      </List>
      <ListItemButton component="a" href={Timeline.path}>
        <ListItemIcon>
          <EventIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={Timeline.title} />
      </ListItemButton>
      <ListItemButton component="a" href={Art.path}>
        <ListItemIcon>
          <AudiotrackIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={Art.title} />
      </ListItemButton>
      <ListItemButton component="a" href={DataDictionary.path}>
        <ListItemIcon>
          <BookmarksIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={DataDictionary.title} />
      </ListItemButton>
    </>
  );
}
