import React from 'react';

import {
    Snackbar,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid
} from '@mui/material';
import { Alert } from '@mui/material';
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import { Bar } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from '../../../helpers/dataManipulation';
import palette from '../../../helpers/palette';


const getChartData = (ediData, indicator, field) => {
    let geographies = ediData.data
        .filter(obj => obj["Indicator"] === indicator)
        .map(obj => obj["Geography"])
        .filter(onlyUnique)
    
    let chromaPalette = palette(geographies.length)
    let colourInd = 0

    // making sure colours always correspond to same geographies
    let staticPalette = {}
    for (let i = 0;  i < geographies.length; i++) {
        staticPalette[geographies[i]] = chromaPalette[i]
    }

    let max = 0
    let min = 100

    let datasets = geographies.map(geography => {
        let data_point = ediData.data
            .filter(obj => obj["Indicator"] === indicator)
            .filter(obj => obj["Geography"] === geography)
            .map(function (obj) {
                if (obj[field] > max) { max = obj[field] }
                if (obj[field] < min) { min = obj[field] }
                return {
                    y: obj["Cycle"],
                    x: obj[field]
                }
            })

        let set = {
            label: geography,
            backgroundColor: chromaPalette[colourInd],
            borderColor: chromaPalette[colourInd],
            data: data_point
        }

        colourInd += 1
        return set
    })

    let spread = max - min

    if ((max + spread) > 100) {
        options.scales.x.max = 100
    } else {
        options.scales.x.max = max + spread
    }

    if ((min - spread) < 0) {
        options.scales.x.min = 0
    } else {
        options.scales.x.min = min - spread
    }

    if (field.match(/percent/i)) {
        // if the field includes "percent" (case insensitive), then it's a percentage value
        options.scales.x.title.text = "Percent (%)"
    } else {
        // otherwise, it must be the median age
        options.scales.x.title.text = "Age (in years)"
    }

    return {
        datasets: datasets
    }
}

const options = {
    indexAxis: 'y',
    plugins: {
        legend: {
            display: true,
        },
    },
    scales: {
        x: {
            stacked: false,
            title: {
                text: "UNITS",
                display: true
            }
        },
        y: {
            stacked: false,
            title: {
                text: "Cycle (year)",
                display: true
            }
        }
    }
};

function getUniqueIndicator(data) {

    // keeping array of single indicators
    // (i.e., excluding indicators like "vulnerable on one or more domains")
    const single_indicators = data
        .map(obj => obj["Indicator"])
        .filter(onlyUnique)
        .filter(function(value, index, arr){ 
            return (
                !(value.match(/or more domains/i))
            )
        })
        .sort()

    // keeping array of indicators which include multiple fields
    // (i.e., keeping ONLY indicators like "vulnerable on one or more domains")
    const multi_indicators = data
        .map(obj => obj["Indicator"])
        .filter(onlyUnique)
        .filter(function(value, index, arr){ 
            return (
                value.match(/or more domains/i)
            )
        })
        .sort()
    
    // putting multi indicators at the end of the list instead of sorting them alphabetically with the rest of the list
    return single_indicators.concat(multi_indicators)
}

function getUniqueField(data, category) {

    // excluding fields we don't want to be selectable
    return Object.keys(data[0])
        .filter(function(value, index, arr){ 
            return (
                (value !== "Cycle") &&
                (value !== "Geography") &&
                (value !== "Indicator") &&
                (value !== "Numerator") &&
                (value !== "Denominator")
            )
        })
}

const EDI = () => {
    const sourceCode = "get_edi"
    const { data: ediData, isFetching, isError } = useRecord(sourceCode, null);

    const [indicator, setIndicator] = React.useState("Vulnerable on one or more domains");
    const [uniqueIndicator, setUniqueIndicator] = React.useState([])

    const [field, setField] = React.useState("Percent vulnerable");
    const [uniqueField, setUniqueField] = React.useState([])

    React.useEffect(() => {
        if (ediData) {
            setUniqueIndicator(getUniqueIndicator(ediData[0].data));
        }
    }, [ediData])

    React.useEffect(() => {
        if (ediData) {
            setUniqueField(getUniqueField(ediData[0].data));
        }
    }, [ediData])

    const handleIndicatorChange = (event) => {
        const {
            target: { value },
        } = event;

        setIndicator(
            value,
        );
    };

    const handleFieldChange = (event) => {
        const {
            target: { value },
        } = event;

        setField(
            value,
        );
    };

    return (
        <>
            {isError &&
                <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Something went wrong. Please try reloading the page.
                    </Alert>
                </Snackbar>
            }
            {isFetching &&
                <Skeleton variant="rectangular" height={200} />
            }
            {ediData &&
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <FormControl sx={{ width: '100%'}}>
                                <InputLabel id="indicator-simple-select-label">Indicator</InputLabel>
                                <Select
                                    labelId="indicator-simple-select-label"
                                    id="indicator-simple-select"
                                    value={indicator}
                                    label="Indicator"
                                    onChange={handleIndicatorChange}
                                >
                                    {uniqueIndicator.map((option) => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormControl sx={{ width: '100%'}}>
                                <InputLabel id="field-simple-select-label">Field</InputLabel>
                                <Select
                                    labelId="field-simple-select-label"
                                    id="field-simple-select"
                                    value={field}
                                    label="field"
                                    onChange={handleFieldChange}
                                >
                                    {uniqueField.map((option) => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Bar data={getChartData({ ...ediData[0] }, indicator, field)} options={options} />
                        </Grid>
                    </Grid>
                    <DataSourceRef code={sourceCode} />
                </>
            }
        </>
    )
}

export default EDI;
