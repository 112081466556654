export default function fullDateTime(datetime) {
  if (!datetime) {
    return '-';
  }
  let date
  if (typeof datetime === "string") {
    date = new Date(datetime)
  } else {
    date = datetime
  }
  return date.toLocaleString();
}