import React, { useRef, useEffect } from "react";
import { loadModules } from "esri-loader";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

const sched7url = 'https://api.cityofkingston.ca/gis_unfed/rest/services/Planning/OP7_NatHeritageA/MapServer'
const sched8url = 'https://api.cityofkingston.ca/gis_unfed/rest/services/Planning/OP8_NatHeritageB/MapServer'

export default function Schedule78(props) {
  const mapEl = useRef(null);

  useEffect(() => {
    let view

    loadModules( ["esri/views/MapView", "esri/Map", "esri/layers/MapImageLayer", "esri/widgets/Legend"], { css: true } )
    .then(([MapView, Map, MapImageLayer, Legend]) => {

      let layers = [sched7url, sched8url].map(url => new MapImageLayer({url: url}))
      
      const map = new Map({ layers: layers, ...props.mapConf });

      view = new MapView({ map: map, container: mapEl.current, ...props.mapViewConf});

      let legend = new Legend({
        view: view
      });

      view.ui.add(legend, "top-right");
    });
    return () => {
      if (!!view) {
        view.destroy();
        view = null;
      }
    };
  }, [props]);
  return (
    <>
      <div ref={mapEl} {...props.divProps} />
      <DataSourceRef code='nhs_map'/>
    </>
  )
}

Schedule78.defaultProps = {
  divProps: {
    style: {height: 720}
  },
  mapConf: {
    basemap: "gray-vector",
  },
  mapViewConf: {
    zoom: 10,
    center: [-76.3798075,44.3218493],
  }
}