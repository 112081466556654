import * as React from 'react';
// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
//   >
//     •
//   </Box>
// );

export default function TVCard({rank, title, description, index}) {
    return (
      <Card style={{backgroundColor: "#f8f7ff", height:'22rem'}} >
        <CardMedia/>
        <CardContent>
        <Typography gutterBottom variant="h5" component="div">
            {rank+'#'}
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            {title}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {description}
          </Typography>
          <Typography gutterBottom variant="h7" component="div">
            Index
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {index}
          </Typography>
          
        </CardContent>
      </Card>
    );
  }

  TVCard.defaultProps = {
    rank:1,
    title: 'test',
    description: 'test',
    index: 100
  }