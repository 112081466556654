import React from 'react';

import { Skeleton, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

import { Line } from "react-chartjs-2";
import 'chartjs-adapter-moment';

import { useRecord } from "../../../api/records";
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import palette from '../../../helpers/palette';
import chroma from 'chroma-js';


const getChartData = (wastedata, industry) => {

  let industries = []

  // If only one field is given in the industry, put it into an array
  if (Array.isArray(industry)) {
    industries = industry
  } else {
    industries = [industry]
  }

  let chromaPalette = palette(1);

  let lighterColour = chroma(chromaPalette[0]).brighten(1.5);

  let datasets = industries.map((industry) => {

    let data_point = wastedata.data.filter(obj => obj["Waste Type"].trim() === industry)
      .map(obj => ({ x: obj["Date"], y: obj["Daily Total"] }))

    let set = {
      backgroundColor: chromaPalette[0],
      borderColor: lighterColour,
      tension: 0.1,
      label: industry + " collected",
      data: data_point,
    }

    return set
  })

  return {
    datasets: datasets
  }
}

let options = {
  scales: {
    y: {
      title: {
        display: true,
        text: "Kg of material"
      }
    },
    x: {
      type: 'time',
      time: {
        parser: 'YYYY-MM-DD',
        tooltipFormat: 'MMM DD, YYYY',
        unit: 'month'
      }
    }
  }
};

const Waste = (props) => {
  const sourceCode = "get_waste"
  const { data: wastedata, isFetching, isError } = useRecord(sourceCode, null)

  return (
    <>
      {isError &&
        <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      }
      {isFetching &&
        <Skeleton variant="rectangular" height={500} />
      }
      {wastedata &&
        <>
          <Line data={getChartData({ ...wastedata[0] }, props.industry)} options={options} />
          <DataSourceRef code={sourceCode} />
        </>
      }
    </>
  )
}

export default Waste;

Waste.defaultProps = {
  industry: 'Garbage'
}