import React from 'react';
import {
    Snackbar,
    Skeleton,
    Grid,
} from '@mui/material';
import { Alert } from '@mui/material';
import DataSourceRef from '../../common/footnotes/DataSourceRef';
import { Bar } from "react-chartjs-2";
import { useRecord } from "../../../api/records";

import palette from '../../../helpers/palette';
import onlyUnique from '../../../helpers/dataManipulation'

const getChartData = (openDaysData) => {
    let years = openDaysData.data.map(obj => obj["Year"]).filter(onlyUnique)

    let colourInd = 0
    let chromaPalette = palette(years.length)

    let datasets = years.map(year => {
        let datapoint = openDaysData.data
            .filter(obj => obj["Year"] === year)
            .map(function (obj) {
                return {
                    x: obj["Day"],
                    y: obj["Count"]
                }
        })

        let set = {
            label: year,
            backgroundColor: chromaPalette[colourInd],
            borderColor: chromaPalette[colourInd],
            data: datapoint
        }

        colourInd += 1
        return set
    })
    
    return {
        datasets: datasets
    }
}


const options = {
    plugins: {
        legend: {
            display: true,
        },
    },
    scales: {
        x: {
            stacked: false,
            title: {
                text:"Day of the Week",
                display: true
            }
        },
        y: {
            stacked: false,
            title: {
                text: "Number of GLAMs Open",
                display: true
            }
        }
    }
};

const OpenDays = () => {
    const sourceCode = "get_open_days"
    const { data: openDaysData, isFetching, isError } = useRecord(sourceCode, null);

    return (
        <>
            {isError &&
                <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Something went wrong. Please try reloading the page.
                    </Alert>
                </Snackbar>
            }
            {isFetching &&
                <Skeleton variant="rectangular" height={200} />
            }
            {openDaysData &&
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Bar data={getChartData({ ...openDaysData[0] })} options={options} />
                        </Grid>
                    </Grid>
                    <DataSourceRef code={sourceCode} />
                </>
            }
        </>
    )
}

export default OpenDays;

