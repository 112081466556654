import React from 'react';

import { Skeleton, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

import { Line } from "react-chartjs-2";
import 'chartjs-adapter-moment';

import { useRecord } from "../../../api/records";
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import palette from '../../../helpers/palette';
import chroma from 'chroma-js';

function getIndustryColor(key) {
  key = String(key)
  let chromaPalette = palette(6)
  
  switch (key) {
    case 'retail_and_recreation_percent_change_from_baseline':
      return {
        backgroundColor: chromaPalette[0],
        borderColor: chroma(chromaPalette[0]).brighten(1.5)
      }
    case 'grocery_and_pharmacy_percent_change_from_baseline':
      return {
        backgroundColor: chromaPalette[1],
        borderColor: chroma(chromaPalette[1]).brighten(1.5)
      }
    case 'parks_percent_change_from_baseline':
      return {
        backgroundColor: chromaPalette[2],
        borderColor: chroma(chromaPalette[2]).brighten(1.5)
      }
    case 'transit_stations_percent_change_from_baseline':
      return {
        backgroundColor: chromaPalette[3],
        borderColor: chroma(chromaPalette[3]).brighten(1.5)
      }
    case 'workplaces_percent_change_from_baseline':
      return {
        backgroundColor: chromaPalette[4],
        borderColor: chroma(chromaPalette[4]).brighten(1.5)
      }
    case 'residential_percent_change_from_baseline':
      return {
        backgroundColor: chromaPalette[5],
        borderColor: chroma(chromaPalette[5]).brighten(1.5)
      }
    default: 
      return {
        backgroundColor: chromaPalette[0],
        borderColor: chroma(chromaPalette[0]).brighten(1.5)
      }
  }
}

const formatHeader = header => {
  let dividedStr = header.split("_")
  for (let i = 0; i < dividedStr.length; i++) {
    dividedStr[i] = dividedStr[i].charAt(0).toUpperCase() + dividedStr[i].substring(1)
  }
  return dividedStr.join(" ")
}

const getChartData = (googlemobilitydata, industry) => {

  let industries = []

  // If only one field is given in the industry, put it into an array
  if (Array.isArray(industry)) {
    industries = industry
  } else {
    industries = [industry]
  }

  let datasets = industries.map((industry) => {
    let data_point = googlemobilitydata.data.map(obj => ({x: obj["date"], y: obj[industry]}))

    let set = {
        backgroundColor: getIndustryColor(industry).backgroundColor,
        borderColor: getIndustryColor(industry).borderColor,
        tension: 0.1,
        label: formatHeader(industry),
        data: data_point,
    }
    return set
  })

  // Use first and last points from data for baseline points
  let baselinePoints = [{x: googlemobilitydata.data[0].date, y: 0}, {x: googlemobilitydata.data[googlemobilitydata.data.length - 1].date, y: 0}]

  let baselineSet = {
    borderDash: [10, 5],
    backgroundColor: '#FFFFFF',
    borderColor: '#797679',
    borderWidth: 3,
    pointRadius: 0,
    pointHitRadius: 0,
    label: 'Baseline',
    data: baselinePoints,
  }

  datasets.push(baselineSet);

  return {
    datasets: datasets
  }
}

const options = {
    scales: {
      y: {
        title: {
          display: true,
          text: "Percent Change From Baseline"
        }
      },
      x: {
        title: {
          display: true,
          text: "Month"
        },
        type: 'time',
        time: {
          parser: "YYYY-MM-DD",
          tooltipFormat:'MMM DD, YYYY'
        }
      }
    }
};

const GoogleMobility = (props) => {
  const sourceCode = "get_mobility"
  const { data: googlemobilitydata, isFetching, isError } = useRecord(sourceCode, null)

  return (
    <>
      {isError &&
        <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      }
      {isFetching &&
        <Skeleton variant="rectangular" height={500}/>
      }
      {googlemobilitydata &&
        <>
          <Line data={getChartData({ ...googlemobilitydata[0] }, props.industry)} options={options} />
          <DataSourceRef code={sourceCode}/>
        </>
      }
    </>
  )
}

export default GoogleMobility;

GoogleMobility.defaultProps = {
  industry: 'workplaces_percent_change_from_baseline'
}