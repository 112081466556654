import * as React from 'react';
import {
  Typography,
} from '@mui/material';

export default function DashboardSubtitle(props) {
  let typoProps = {
    variant: 'body1',
    //color: 'text.secondary',
    ...props
  }
  return React.createElement(Typography, typoProps)
}