import * as React from 'react';

import { List, ListItem, ListItemText, Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails, Link } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import fullDateTime from '../../helpers/datetime';

function smartText(text) {
  const fullUrlRe = /https*:\/\/\S*/
  let urls = text.match(fullUrlRe)
  if (urls) {
    let urlDisplay = urls[0]
                      .split("//",2)[1]
                      .split("/",2)[0]
                      .split("?",2)[0]
    return <Link href={urls[0]}>{urlDisplay}</Link>
  } else {
    return text
  }
}

export default function SourceCard(props) {
  let metaList = Object.entries(props.meta)
  metaList = metaList.filter(([field, value], i) => field !== '')
  metaList = metaList.sort((a,b) => {
    if(a[0] < b[0]) { return -1; }
    if(a[0] > b[0]) { return 1; }
    return 0;
  })

  return (
    <Card sx={{p:3}} id={props.id}>
      <CardContent>
        <Typography variant="h5">{props.name}</Typography>
        <Typography variant="subtitle1" sx={{mb:3}}>{props.source}</Typography>
        {metaList.length > 0 &&
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body1">Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <List>
                {metaList.map( (info, i) =>
                  <ListItem key={i}>
                    <ListItemText
                      sx={{wordBreak:"break-word"}}
                      primary={info[0]}
                      // secondary={info[1]}
                      secondary={smartText(info[1])}
                    />
                  </ListItem>
                )}
              </List>
              </AccordionDetails>
            </Accordion>
          </>
        }
        <Typography sx={{mt:3}} variant="body1">Last retrieval</Typography>
        <Typography variant="body2">{fullDateTime(props.retrieved)}</Typography>
      </CardContent>
    </Card>
  );
}

SourceCard.defaultProps = {
  name: "",
  source: "",
  meta: {},
  retrieved: "",
  id: ""
}