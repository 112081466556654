import React from 'react';

import {
    Snackbar,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Checkbox,
    ListItemText,
    FormLabel,
    FormControlLabel,
    Radio,
    RadioGroup,
    Grid
} from '@mui/material';
import { Alert } from '@mui/material';
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import { Line } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from '../../../helpers/dataManipulation';
import palette from '../../../helpers/palette';


const getChartData = (homelessnessData, category, characteristic, format) => {
    let chromaPalette = palette(characteristic.length)
    let colourInd = 0

    let datasets = characteristic.map(characteristicValue => {
        let data_point = homelessnessData.data
            .filter(obj => obj["Category"] === category)
            .filter(obj => obj["Characteristic"] === characteristicValue)
            .map(function(obj) {
                return {
                    x: obj["Year"] + "-" + obj["Month"],
                    y: obj[format]
                }
            })

        let set = {
            label: characteristicValue,
            data: data_point
        }

        return set
    })

    // sorting the datasets
    // 1- drawing longer datasets first (to avoid technical issues, but these should all be the same length)
    // 2- drawing datasets in alphabetical order by label
    datasets.sort(function (a,b) {
        if (a.data.length < b.data.length) {
            return -1
        } else if (a.data.length > b.data.length) {
            return 1
        } else {
            if (a.label <= b.label) {
                return -1
            } else {
                return 1
            }
        }
    })

    // setting the colours for each dataset AFTER we have sorted them
    datasets.forEach(function (item) {
        item["backgroundColor"] = chromaPalette[colourInd]
        item["borderColor"] = chromaPalette[colourInd]
        colourInd += 1
    })

    if (format === "Count") {
        options.scales.y.min = 0
        options.scales.y.max = null
        options.scales.y.title.text = "Number of people"
        options.scales.y.ticks = {
            callback: function (value) {
                return value
            }
        }
        options.plugins.tooltip = {
            callbacks: {
                label: function (context) {
                    return context.dataset.label + ': ' + context.formattedValue
                }
            }
        }

    // setting the y-axis and tooltip labels to display percentages as "10%" rather than "0.1"
    } else if (format === "Percent") {
        options.scales.y.min = 0
        options.scales.y.max = 1
        options.scales.y.title.text = "Percent"
        options.scales.y.ticks = {
            callback: function (value) {
                return (value * 100) + "%"
            }
        }
        options.plugins.tooltip = {
            callbacks: {
                label: function (context) {
                    // avoiding numbers which have infinite decimals using toFixed()
                    return context.dataset.label + ': ' + (context.formattedValue * 100).toFixed(0) + '%'
                }
            }
        }
    }

    return {
        datasets: datasets
    }
}

const options = {
    spanGaps: false,
    plugins: {
        legend: {
            display: true,
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                parser: "YYYY-MM",
                tooltipFormat: 'MMM YYYY',
                unit: 'month',
            }
        },
        y: {
            title: {
                display: true
            }
        }
    }
};

function getUniqueCategory(data) {
    return data
        .map(obj => obj["Category"])
        .filter(onlyUnique)
        .sort()
}

function getUniqueCharacteristic(data, category) {
    return data
        .filter(obj => obj["Category"] === category)
        .map(obj => obj["Characteristic"])
        .filter(onlyUnique)
}

const Homelessness = () => {
    const sourceCode = "get_homelessness"
    const { data: homelessnessData, isFetching, isError } = useRecord(sourceCode, null);

    const [category, setCategory] = React.useState("Homelessness status");
    const [uniqueCategory, setUniqueCategory] = React.useState([])

    const [characteristic, setCharacteristic] = React.useState(["Actively Homeless", "Chronically Homeless", "Approaching Chronic"]);
    const [uniqueCharacteristic, setUniqueCharacteristic] = React.useState([])

    const [format, setFormat] = React.useState("Count");

    React.useEffect(() => {
        if (homelessnessData) {
            setUniqueCategory(getUniqueCategory(homelessnessData[0].data));
        }
    }, [homelessnessData])

    React.useEffect(() => {
        if (homelessnessData) {
            let characteristics = getUniqueCharacteristic(homelessnessData[0].data, category);
            setUniqueCharacteristic(characteristics);
            setCharacteristic(characteristics);
        }
    }, [homelessnessData, category])

    const handleCategoryChange = (event) => {
        const {
            target: { value },
        } = event;

        setCategory(
            value,
        );
    };

    const handleCharacteristicChange = (event) => {
        const {
            target: { value },
        } = event;
        setCharacteristic(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleFormatChange = (event) => {
        setFormat(event.target.value);
    };

    return (
        <>
            {isError &&
                <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Something went wrong. Please try reloading the page.
                    </Alert>
                </Snackbar>
            }
            {isFetching &&
                <Skeleton variant="rectangular" height={200} />
            }
            {homelessnessData &&
                <>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={12} lg={4}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="category-simple-select-label">Category</InputLabel>
                                <Select
                                    labelId="category-simple-select-label"
                                    id="category-simple-select"
                                    value={category}
                                    label="Category"
                                    onChange={handleCategoryChange}
                                >
                                    {uniqueCategory.sort().map((option) => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="characteristic-multiple-checkbox-label">Characteristic</InputLabel>
                                <Select
                                    labelId="characteristic-multiple-checkbox-label"
                                    id="characteristic-multiple-checkbox"
                                    multiple
                                    value={characteristic}
                                    onChange={handleCharacteristicChange}
                                    input={<OutlinedInput label="Characteristic" />}
                                    renderValue={(selected) => selected.sort().join(', ')}
                                >
                                    {uniqueCharacteristic.sort().map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox checked={characteristic.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} lg={2} >
                            <FormControl hiddenLabel={true}>
                                <RadioGroup
                                    aria-label={<FormLabel id="format-radio-buttons-group">Format</FormLabel>}
                                    name="format-radio-buttons-group"
                                    value={format}
                                    onChange={handleFormatChange}
                                >
                                    <FormControlLabel value="Count" control={<Radio size="small" sx={{ padding: '6px' }} />} label="Count" />
                                    <FormControlLabel value="Percent" control={<Radio size="small" sx={{ padding: '6px' }} />} label="Percent" />
                                </RadioGroup>
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={12} lg={12}>
                            <Line data={getChartData({ ...homelessnessData[0] }, category, characteristic, format)} options={options} />
                        </Grid>
                    </Grid>
                    <DataSourceRef code={sourceCode} />
                </>
            }
        </>
    )
}

export default Homelessness;
