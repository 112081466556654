import * as React from 'react';
import Typography from '@mui/material/Typography';
import DashboardHeader5 from '../typography/DashboardHeader5';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


const SongInfo = [
    {
        "title": "Christina – The Wilderness",
        "description": "The Wilderness is a rock sextet based in Kingston. Since 2015, we've played roughly 500 shows across Canada and the US, and have performed alongside acts such as the Busty and the Bass, the Trews, and the Blue Stones. "
    }, {
        "title": "Promise to Helen – Tiny Horse",
        "description": "Tiny Horse is an emerging Kingston folk/rock band. Narrative lyrics, punctuated harmonies and melodic hooks are sure to run the racetrack of your mind like a Tiny Horse in your head."
    }, {
        "title": "See you Again – Willy Nilly",
        "description": "Willy Nilly is a folk/indie rock band that showcases a number of wide-ranging influences in their songwriting, resulting in a familiar sound that is hard to pinpoint. In 2021, the band released their debut EP 'Confused And Rejuvenated' and debut LP 'Dogs in Heaven'. "
    }, {
        "title": "Calvinish – Kelli Trottier ",
        "description": "Kelli Trottier toured North America and beyond with the sensational Bowfire entourage as one of their select virtuoso fiddlers. Kelli’s vocals and step dancing were also considered highlights of every performance. As well as being nominated three times for fiddle player of the Year by the Canadian Country Music Association, Kelli was inducted into the “North American Fiddlers’ Hall of Fame” in 2018. She has released ten independent recordings, an instructional step dance DVD and three original fiddle books."
    }, {
        "title": "Two Suns – Tyson Sullivan",
        "description": "Tyson is a Singer-Songwriter. With his eclectic tastes in genres, he enjoys blending Rock, Country, Jazz, Gospel, Folk into his own creations. You can catch him performing around Kingston in many of the famous local institutions."
    }, {
        "title": "The Weather – Your Paris",
        "description": "Formed only 2 months before the global pandemic hit, Nick Babcock and Laila Kharouba make up your new favorite pop duo, Your Paris. Self-declared as “Fleetwood Mac meets Ariana Grande,” Your Paris offers an ethereal pop sound with undeniable influences from country/folk, rock, and R&B, featuring intoxicating lyrics that are both relatable and heartbreaking. "
    }, {
        "title": "Love so Big – Gary Rasberry",
        "description": "JUNO-nominated artist Gary Rasberry has been a fixture on the Kingston music and arts scene for over three decades. A noted artist-educator, Gary has held artist-in-residencies internationally, in California, Barcelona, and Amsterdam, and regularly criss-crosses the country as a VIA Rail Artist On Board, from Halifax to Vancouver, stopping along the way to perform at schools, festivals, camps and house concerts. "
    }, {
        "title": "Love for Connoisseurs – Angela Verbrugge",
        "description": "Kingston-born, Amherstview-raised, Ernestown Secondary graduate Angela Verbrugge (pronounced ver-Bruges like the city of Bruges in Belgium) is a jazz vocalist who ''possesses a winsome, brightly burnished, pliable voice, ample emotional intelligence, and considerable songwriting skills.'' (JazzTimes). She caught listeners’ ears with her debut album The Night We Couldn't Say Good Night released in 2019, and she won recognition as ‘best female jazz vocalist’ in the 2021 JazzTimes Readers’ Poll."
    }, {
        "title": "The Flood – Nameless Friends ",
        "description": "Nameless Friends are Canadian rockers with numbers on their chests and hearts on their sleeves. Grand, funny, and vulnerable, the band does big songs for big feelings. Nameless Friends is female-fronted by powerhouse Number One from Kingston, ON, and rounded out by multi-instrumentalist Number Two, guitarist Number Three, and ex-Equinox heavy-hitter Number Four. "
    }, {
        "title": "Sunshine - FASTFAST",
        "description": "Kingston Composer James Mulvale created FASTFAST during the pandemic mixing 60s psychedelia with heartfelt lyrics and vertigo-inducing Microtonal chord changes, I doubt you've heard anything quite like this."
    }, {
        "title": "Call It What You Like – Jenice Rayne",
        "description": "Jenica Rayne is a compelling songwriter, accomplished guitarist and seasoned performer: a storyteller who draws her audience in with lyric driven songs that speak from the heart. Jenica's discography includes: Hall of Memories (1999), Essential Elements (2005) Live Rayne (2007) self-titled EP with the Calamity Janes Band Calamity Janes (2013) Love is Love Compilation CD (2013) Hit The Ground Running (October 2014) and a collection of singles."
    }, {
        "title": "Summer Fights – Michael C. Duguay",
        "description": "Michael C. Duguay is a writer, musician, and performer. First gaining recognition as a versatile and uniquely charismatic supporting player in the early 2000’s, Michael contributed to critically acclaimed breakthrough albums by The Burning Hell and Evening Hymns. After surviving nearly a decade of homelessness and drug addiction, Michael resurfaced in 2018, self-producing and releasing his widely praised sophomore album, The Winter of our Discotheque, which chronicled his years of tumult and established him as one of Canadian music’s most distinct, enigmatic, and honest emerging voices. "
    }, {
        "title": "Ghost Story – Needless Love",
        "description": "Formed by members Sean Tomalty, and Rae Corcoran, Needless Love is a modern two-person band archetype, blending live looping and synth performance with powerful female vocals, bringing together the worlds of Pop and Electronic music."
    }, {
        "title": "Sunshine – The Wizard of Odd ",
        "description": "Justin Holland, known as The Wizard of Odd, has been an artist in Kingston for years. He's released half a dozen EPs with a variety of different projects. He has also produced music videos for a lot of bands around Kingston including The Wilderness, Goldwing, Listen Up Kid, and a lot of others."
    }, {
        "title": "Cheap Thrills – Anthea Feaver",
        "description": "Anthea Feaver is an emerging folk musician. She released her debut EP, Play It Back, in January 2021, which achieved great acclaim on campus and community radio stations across Canada and the U.S. Anthea's music centers around family, loss, memory, and belonging. Her sparse imagery and emotional vulnerability invite her listeners to share her thoughts and feelings and explore their own relationship to these themes."
    }, {
        "title": "Selfish – ATIARI",
        "description": "ATIARI is a singer, songwriter, multi-instrumentalist and producer-in-training with musical influences that span from classical, jazz, R&B/soul, to pop a cappella. As an emerging artist, ATIARI plans to stretch the boundaries of music and live Between The Lines. Enjoy their new single 'Selfish' and stay tuned for their debut album release (Summer 2022)."
    }, {
        "title": "Free for A While – Christopher Jackson ",
        "description": "After 30 years as a working musician in a variety of cover bands and session recordings, multi-instrumentalist Christopher Jackson decided it's time to put all his experiences together and see where his own writing and music might take him. The results are a collection of original recordings reminiscent of his musical influences, such as The Band, CSNY and The Allman Brothers, with a dash of the heavier, edgier Black Sabbath influence from his youth. A true entertainer, Christopher Jackson carries that connection into his recordings, playing the majority of the instruments and singing each harmony himself. Co-producing with his longtime friend, band mate and recording engineer Paul Halladay, they create a listening experience that is authentic, real and relatable."
    }, {
        "title": "Better Off – Astrid Tanton ",
        "description": "Astrid Tanton has been sharing her music & singing since 3 years old, adding drums at 7, guitar & piano at 11. Residing just north of Kingston in Verona, South Frontenac and graduating grade 8 from Prince Charles Public School in 2015 Astrid moved to Toronto to attend Rosedale Heights School of the Arts – at 12 years old. She graduated RHSA in 4 years with grade 12 high school honours in June 2019 and is now in her second year at Ryerson (X) University. Her music is written with a simple honesty and her sound is unique and hard to forget. "
    }, {
        "title": "Here's to Love – Michael George",
        "description": "Michael George is a head chorister & soloist at St. Georges Cathedral choir. He has been a band leader & solo artist for 45 years. He is the First-Place winner of 11 International Songwriting Awards and has taught music in Kingston and Los Angeles. Duguay has also opened for many artists including Blue Rodeo, Tom Cochrane, Ron Sexmith, Chilliwack, Leahy, Cowboy Junkies, Long John Baldry, and many others."
    }, {
        "title": "Lost in Love – Sadaf Amini",
        "description": "Sadaf Amini began learning the Santur when she was a child. Later, she entered the Tehran School of Music high school. In 2012, she received her Bachelor’s degree in Iranian Music Performance at Tehran University. After immigrating to Canada, she received her Master’s degree in Music Technology at the University of Toronto in 2018. She has performed at various music festivals and received prizes, which the latest were the 2019 YGK emerging musician competition (Kingston), and the 2020 Kingston Film Festival Local Music Video Showcase Jury Prize (Kingston). She has also collaborated with various ensembles that have led to several album recordings; her most recent release was her original Santur solo album “Flow”."
    }, {
        "title": "It’s Not Over Til It’s Over – Michael K. Myers",
        "description": "Michael K. Myers has over 50 years of professional experience in the music business, most of it in the Kingston area. From being the accompanist composer for touring Theatre 5 in the 70s and 80s to being the lead vocalist for The Big Phat Horn Band that plays monthly at the RCHA in Kingston, Michael has numerous recordings of his songwriting available to the public."
    }
]


export default function KingstonSoundsDetails(props) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                <Typography>Playlist Description </Typography>
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='md'
            >
                <DialogTitle>
                    {"YGK Music 2022 - Kingston Sounds"}
                </DialogTitle>

                <DialogContent>
                    {SongInfo.map((datum) => {
                        if (datum.title === null) return ('')
                        return (
                            <>
                                <DashboardHeader5>
                                    {datum.title}
                                </DashboardHeader5>
                                <Typography variant="body1" sx={{ mb: 3 }}>
                                    {datum.description}
                                </Typography>
                            </>
                        )
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Go Back
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}