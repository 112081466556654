import React from 'react';

import { Skeleton, Snackbar } from '@mui/material';
import {
  Alert,
  Radio,
  RadioGroup,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel
} from '@mui/material';

import { Bar } from "react-chartjs-2";

import 'chartjs-adapter-moment';

import { useRecord } from "../../../api/records";
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import palette from '../../../helpers/palette';

const getChartData = (airtrafficdata, sector, format) => {

  let allSectors = []

  // If only one field is given in the sector, put it into an array
  if (Array.isArray(sector)) {
    allSectors = sector
  } else {
    allSectors = [sector]
  }

  let colourInd = 0

  let chromaPalette = palette(allSectors.length)

  let datasets = allSectors.map((sector) => {
    let data_points = airtrafficdata.data
      .filter(obj => obj['Air passenger traffic'] === sector)
      .map(obj => ({
        x: obj["REF_DATE"].toString(),
        y: obj["VALUE"]
      }))

    if (format === "Percent Change") {
      data_points = data_points.map((obj, index) => {
        if (index === 0) {
          obj.percent = 0
          return obj
        } else {
          obj.percent = ((data_points[index].y - data_points[index - 1].y) / data_points[index - 1]["y"]) * 100
          return obj
        }
      })

      // getting rid of the first data point (it's zero since there's no previous year to calculate change with)
      data_points.shift()

      data_points.forEach(obj => {
        obj.y = obj.percent
        delete obj.percent
      })
    }

    let set = {
      backgroundColor: chromaPalette[colourInd],
      borderColor: chromaPalette[colourInd],
      tension: 0.1,
      label: sector,
      data: data_points,
    }
    colourInd += 1
    return set
  })

  // axis & tooltip formatting --------------------------------------------------------------------
  if (format === "VALUE") {
    options.plugins.tooltip = {
      callbacks: {
        label: function (context) {
          return context.dataset.label + ': ' + context.formattedValue
        }
      }
    }
  } else {
    options.plugins.tooltip = {
      callbacks: {
        label: function (context) {
          return context.dataset.label + ': ' + parseFloat(context.formattedValue).toFixed(1) + '%'
        }
      }
    }
  }

  return {
    datasets: datasets
  }
}

const options = {
  plugins: {
    legend: {
      display: true,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: "Number of Passengers"
      }
    },
    x: {
      title: {
        display: true,
        text: "Year"
      }
    }
  }
};

const AirTraffic = (props) => {
  const sourceCode = "get_air_traffic"
  const { data: airtrafficdata, isFetching, isError } = useRecord(sourceCode, null)

  const [format, setFormat] = React.useState("VALUE");

  const handleFormatChange = (event) => {
    setFormat(event.target.value);

    if (event.target.value === "VALUE") {
      options.scales.y.title.text = "Number of Passengers"
    } else {
      options.scales.y.title.text = "Percent Change"
    }
    
  };

  return (
    <>
      {isError &&
        <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      }
      {isFetching &&
        <Skeleton variant="rectangular" height={500} />
      }
      {airtrafficdata &&
        <>
          <Grid container spacing={3} alignItems='center'>
            <Grid item xs={12} lg={12} sx={{ mb: 2 }}>
              <FormControl hiddenLabel={true}>
                <RadioGroup
                  aria-label={<FormLabel id="format-radio-buttons-group">Format</FormLabel>}
                  name="format-radio-buttons-group"
                  value={format}
                  onChange={handleFormatChange}
                >
                  <FormControlLabel value="VALUE" control={<Radio size="small" sx={{ padding: '6px' }} />} label="Number of Passengers" />
                  <FormControlLabel value="Percent Change" control={<Radio size="small" sx={{ padding: '6px' }} />} label="Percent Change" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Bar data={getChartData({ ...airtrafficdata[0] }, props.sector, format)} options={options} />
          </Grid>
          <DataSourceRef code={sourceCode} />
        </>
      }
    </>
  )
}

export default AirTraffic;

AirTraffic.defaultProps = {
  sector: ['Total, passenger sector', 'Domestic sector', 'Transborder sector', 'Other International sector']
}