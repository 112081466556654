import * as React from 'react';

import {
  Grid,
  Typography
} from '@mui/material';

import DashboardContainer from '../../layout/DashboardContainer';
import DashboardTitle from '../../typography/DashboardTitle';
import VisualizationTitle from '../../typography/VisualizationTitle';
import VisualizationNarrative from '../../typography/VisualizationNarrative';



import Homelessness from '../../indicators/community_vulnerabilities/Homelessness';
import FoodProviderSurvey from '../../indicators/community_vulnerabilities/FoodProviderSurvey';
import EDI from '../../indicators/community_vulnerabilities/EDI';
import HealthCharacteristic from '../../indicators/community_vulnerabilities/HealthCharacteristics';
import LowIncomeFamilies from '../../indicators/community_vulnerabilities/LowIncomeFamilies';
import OpioidData from '../../indicators/community_vulnerabilities/OpioidData';
import ReadMore from '../../cards/ReadMore';



const CommunityVulnerabilities = (props) => {
  return (
    <DashboardContainer sx={{ mt: 7 }}>
      <DashboardTitle sx={{ mb: 3 }}>{CommunityVulnerabilities.title}</DashboardTitle>
      <Typography variant="body1" sx={{ mb: 1 }}>
        This interactive dashboard explores select indicators of community health and examines how they may have been impacted by the COVID-19 pandemic. It is important to remember that these indicators only help illustrate a portion of the ongoing risks and impacts on health and resiliency in a community and that ongoing insights and research is needed.
      </Typography>
      <Typography sx={{ mb: 4 }}>
        <ReadMore>
          The COVID-19 pandemic has had deep and widespread impacts across each of our lives and can be seen throughout our communities. From school closures, lack of access to food and other basic services, to those that may have lost their jobs or homes, have all had enormous and lasting impacts that are not yet fully understood. All of these circumstances, plus many other unique situations created out of the pandemic have increased tensions in families and individuals impacting their overall health and well-being.
        </ReadMore>
      </Typography>
      <Grid container spacing={7} sx={{ mb: 7 }}>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Early Years Developmental Health &amp; Well-being</VisualizationTitle>
          <VisualizationNarrative sx={{ mt: 1 }}>
            The developmental health (skills and behaviour) of children at school-entry is associated with ongoing success in school and has long-term consequences for individuals and populations. Developmental health is a holistic concept and includes children’s physical, social, emotional, language and cognitive skills.
          </VisualizationNarrative>
          <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
            Monitoring developmental health using the Early Developmental Instrument (EDI) allows communities to develop strategies to foster healthy child development. One of the key measures of the EDI is the percentage of children who are vulnerable, which captures those that may be struggling but have not been formally identified. This population level data helps inform programs and policies that support healthy child development for all.
          </VisualizationNarrative>
          <EDI />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Families Living in Low Income</VisualizationTitle>
          <VisualizationNarrative sx={{ mt: 1 }}>
            Low income can be measured by the Low-income Measure, After tax (LIM-AT), which is a relative measure that refers to a fixed percentage (50%) of median adjusted income of private households. This national concept does not change based on geography size or location but does adjust for different household sizes. The after-tax figures represent the amount of income that individuals and/or families have available to spend.
          </VisualizationNarrative>
          <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
            Although low income is an important concept in understanding vulnerable populations, it is important to understand that poverty is complex, multifaceted and goes beyond income. Information on low income status captures only one piece of the diversity of experiences for those living in poverty.
          </VisualizationNarrative>
          <LowIncomeFamilies />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Overall Health &amp; Well-being</VisualizationTitle>
          <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
            At every stage of life, health is determined by a set of complex interactions that influence our overall health and wellness. Understanding the health characteristics of a community helps to increase supports and allocate resources where they are needed. This summary provides statistics on a variety of health characteristics of the population in Ontario and the Kingston health region. The data provided is collected through the Canadian Community Health Survey (CCHS) by Statistics Canada. The CCHS is a voluntary, cross-sectional survey that collections information related to health status, health system utilization and health determinants for the Canadian population.
          </VisualizationNarrative>
          <HealthCharacteristic />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Opioid-related Morbidity and Mortality</VisualizationTitle>
          <VisualizationNarrative sx={{ mb: 2 }}>
            This graph uses data from Public Health Ontario and allows users to explore the most recent opioid-related morbidity and mortality data including emergency department visits, hospitalizations and deaths.
            Kingston, like many regions across Canada, has seen the COVID-19 pandemic significantly impact the ongoing opioid overdose crisis and exacerbating substance use-related harms. Data suggests there has been a steady increase in opioid-related harms in the KFL&A Health region.
          </VisualizationNarrative>
          <OpioidData />
        </Grid>

        {/* <Grid item xs={12} lg={12}>
          <VisualizationTitle>Kingston Food Provider Survey</VisualizationTitle>
          <VisualizationNarrative sx={{ mt: 1, mb: 1 }}>
            Food providers in Kingston and the Greater Kingston Area recorded meals/services provided daily before and during the COVID-19 pandemic. It is evident in the data that there is an overall increase in the number of individuals served over the course of the COVID-19 pandemic for the organizations that were able to operate within health measures (e.g. delivering meals or hosting distribution events outdoors).
          </VisualizationNarrative>
          <Typography sx={{ mb: 4 }}>
            <ReadMore>
              Operations increased despite the other rising trends of home cooking, likely due to constrained finances or lack of access to cooking equipment or food. One large decrease seen is the Food Sharing Project which operates in school systems, and due to school reductions and/or intermittent closures, the number of children served decreased over the year.
              Preliminary data from 2022 is already showing an increase in individuals served daily across all of the food provider organizations- a reflection of the quick and high rise in inflation across the country- an inflation that vastly affected the food sector and has pushed individuals to alternative food procurement options.
            </ReadMore>
          </Typography>
          <FoodProviderSurvey />
        </Grid> */}

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Homelessness</VisualizationTitle>
          <VisualizationNarrative sx={{ mt: 1 }}>
            Working collaboratively, the City of Kingston along with community partners support the collection of real-time data through the “By-Name-List” program and is displayed in this graph. Homelessness is defined within Canada as a situation where an “individual, family or community without stable, safe, permanent, appropriate housing, or the immediate prospect, means and ability of acquiring it”. The By-Name List system underwent a system upgrade, along with increase training for staff and community partner organizations.  As a result, the data starting in October 2022 and onward will look higher than in previous months.  If you have any questions, please contact <a target="_blank" href='mailto:housing@cityofkingston.ca' style={{ color: '#09169A' }}>housing@cityofkingston.ca</a>.
          </VisualizationNarrative>
          <VisualizationNarrative sx={{ mt: 1 }}>
              The realities that surround each individual situation are unique and there are many reasons why a person may be experiencing homelessness. These may include barriers created through systems intended to provide support, transitions between systems or programs, and the overwhelming nature of navigating different systems in order to secure everyday basic needs.
            </VisualizationNarrative>
            <VisualizationNarrative sx={{ mt: 1, mb: 2 }}>
              The pandemic has only exponentially increased overall stress and challenges within the community, including those experiencing homelessness. The increase in barriers and challenges created through the pandemic will have massive consequences that will increase many risk factors, including many that have contributed to homelessness, and the impact it will have for years to come. Through the collection of real time, actionable data there is an increased understanding of barriers, therefore informing policies and allocation of resources to better support unhoused individuals.
            </VisualizationNarrative>
          <ReadMore>
            <hr></hr>
            <VisualizationNarrative sx={{ mt: 2, fontWeight: 'bold', fontSize: 17 }}>
              Definitions that are currently being used by the Built For Zeros By-Name List:
            </VisualizationNarrative>
            <VisualizationNarrative sx={{ mt: 1 }}>
              Actively Homeless is defined by The Canadian Observatory of Homelessness as: <br />
              “Homelessness describes the situation of an individual, family or community without stable, safe, permanent, appropriate housing, or the immediate prospect, means and ability of acquiring it. It is the result of systemic or societal barriers, a lack of affordable and appropriate housing, the individual/household’s financial, mental, cognitive, behavioural or physical challenges, and/or racism and discrimination.”
            </VisualizationNarrative>
            <VisualizationNarrative sx={{ mt: 1 }}>
              Approaching chronic homelessness are those individuals who have been homeless for an extended period of time and are in fact reaching the definition of chronically homeless.
            </VisualizationNarrative>
            <VisualizationNarrative sx={{ mt: 1 }}>
              Chronic Homelessness, as of April 1, 2019, Reaching Home Directives include the following definition of, which was adopted by BFZ-C (Built for Zero Canada);
              “Refers to individuals who are currently experiencing homelessness AND who meet at least 1 of the following criteria:
              <ul>
                <li>they have a total of at least 6 months (180 days) of homelessness over the past year</li>
                <li>they have recurrent experiences of homelessness over the past 3 years, with a cumulative duration of at least 18 months (546 days)</li>
              </ul>
              Chronic homelessness includes time spent in the following contexts:
              <ol>
                <li>Staying in unsheltered locations, that is public or private spaces without consent or contract, or places not intended for permanent human habitation (Canadian Observatory on Homelessness (COH) Typology: 1.1 and 1.2).</li>
                <li>Staying in emergency shelters, including overnight shelters for people experiencing homelessness (including those for specific populations, such as youth, families, and newcomers), shelters for people impacted by family violence, and emergency shelters for people fleeing a natural disaster or destruction of accommodation (COH Typology: 2.1, 2.2, and 2.3).</li>
                <li>Staying temporarily with others without guarantee of continued residency or the immediate prospects for accessing permanent housing, or short-term rental accommodations (for example, motels) without security of tenure (COH typology: 3.2 and 3.3).</li>
              </ol>
              It does not include situations where individuals have access to secure, permanent housing, whether subsidized or not. The definition also does not include time spent in transitional housing or in public institutions (for example, health and corrections), although individuals who are discharged into homelessness from transitional housing or public institutions can be considered chronically homeless if they were experiencing chronic homelessness upon entry to transitional housing or the public institution.”
            </VisualizationNarrative>
          </ReadMore>

          <Homelessness />
        </Grid>

      </Grid>
    </DashboardContainer>
  )
}

export default CommunityVulnerabilities;

CommunityVulnerabilities.path = "/dashboards/community-health"
CommunityVulnerabilities.title = "Community Health"
CommunityVulnerabilities.description = "This dashboard explores select indicators for measuring community health and examines the impact of the COVID-19 pandemic on community well-being."