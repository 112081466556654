import React from 'react';

import {
    Snackbar,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Grid
} from '@mui/material';
import { Alert } from '@mui/material';
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import { Line } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from '../../../helpers/dataManipulation';
import palette from '../../../helpers/palette';

const getChartData = (taxFilersOntarioData, taxFilersKingstonData, characteristic, location) => {
    let chromaPalette = palette(2)
    
    let staticPalette = {
        Kingston: chromaPalette[0],
        Ontario: chromaPalette[1]
    }

    let taxFilersData = {
        Ontario: taxFilersOntarioData,
        Kingston: taxFilersKingstonData
    }

    let datasets = location.map(locationValue => {
        let data_point = taxFilersData[locationValue].data
            .filter(obj => obj["Canadian tax filers"] === characteristic)
            .map(function (obj) {
                return {
                    x: obj["REF_DATE"].toString(),
                    y: obj["VALUE"]
                }
            })

        let set = {
            label: locationValue,
            backgroundColor: staticPalette[locationValue],
            borderColor: staticPalette[locationValue],
            data: data_point
        }

        return set
    })

    // sorting the datasets
    // 1- drawing longer datasets first (to avoid technical issues, but these should all be the same length)
    // 2- drawing datasets in alphabetical order by label
    datasets.sort(function (a,b) {
        if (a.data.length < b.data.length) {
            return -1
        } else if (a.data.length > b.data.length) {
            return 1
        } else {
            if (a.label <= b.label) {
                return -1
            } else {
                return 1
            }
        }
    })

    let unitOfMeasurement = taxFilersOntarioData.data
        .filter(obj => obj["Canadian tax filers"] === characteristic).map(obj => obj.UOM)[0]
    //let factor = taxFilersOntarioData.data
    //    .filter(obj => obj["Canadian tax filers"] === characteristic).map(obj => obj.SCALAR_FACTOR)[0]
    //options.scales.y.min = 0

    if (unitOfMeasurement === "Number") {
        options.scales.y.title.text = "Number of People (x1000)"
        options.scales.y.ticks = {
            maxTicksLimit: 10,
            callback: function (value) {
                return (value / 1000).toFixed(0)
            }
        }
        options.plugins.tooltip = {
            callbacks: {
                label: function (context) {
                    return context.dataset.label + ': ' + context.formattedValue + ' people'
                }
            }
        }

    } else if (unitOfMeasurement === "Percent") {
        options.scales.y.title.text = "Percentage"
        options.scales.y.ticks = {
            maxTicksLimit: 20,
            callback: function (value) {
                return value + "%"
            }
        }
        options.plugins.tooltip = {
            callbacks: {
                label: function (context) {
                    return context.dataset.label + ': ' + context.formattedValue + '%'
                }
            }
        }
    } else if (unitOfMeasurement === "Dollars") {
        options.scales.y.title.text = "Dollars"
        options.scales.y.ticks = {
            maxTicksLimit: 20,
            callback: function (value) {
                return '$' + value
            }
        }
        options.plugins.tooltip = {
            callbacks: {
                label: function (context) {
                    return context.dataset.label + ': $' + context.formattedValue
                }
            }
        }
    } else if (unitOfMeasurement === "Years") {
        options.scales.y.title.text = "Years"
        options.scales.y.ticks = {
            maxTicksLimit: 20,
            callback: function (value) {
                return value
            }
        }
        options.plugins.tooltip = {
            callbacks: {
                label: function (context) {
                    return context.dataset.label + ': ' + context.formattedValue
                }
            }
        }
    } else {
        options.scales.y.title.text = ""
        options.scales.y.ticks = {
            maxTicksLimit: 20,
            callback: function (value) {
                return value
            }
        }
        options.plugins.tooltip = {
            callbacks: {
                label: function (context) {
                    return context.dataset.label + ': ' + context.formattedValue
                }
            }
        }
    }

    if (characteristic === "Total income of tax filers") {
        options.scales.y.title.text = "Dollars (x1000)"
        options.scales.y.ticks = {
            maxTicksLimit: 10,
            callback: function (value) {
                return (value / 1000).toFixed(0)
            }
        }
    } 

    return {
        datasets: datasets
    }
}

const options = {
    plugins: {
        legend: {
            display: true,
        },
    },
    scales: {
        x: {
            title: {
                text: "Year",
                display: true
            },
            type: 'time',
            time: {
                parser: "YYYY",
                tooltipFormat: 'YYYY',
                unit: 'year',
            }
        },
        y: {
            title: {
                display: true
            }
        }
    }
};

function getUniqueCharacteristic(data1, data2) {
    let unique1 = data1
        .map(obj => obj["Canadian tax filers"])
        .filter(onlyUnique)

    let unique2 = data2
        .map(obj => obj["Canadian tax filers"])
        .filter(onlyUnique)

    return Object.values({ ...unique1, ...unique2 })
}

const TaxFilers = () => {
    const sourceCodeOntario = "get_tax_filers_ontario"
    const { data: taxFilersOntarioData, isFetching: isFetchingO, isError: isErrorO } = useRecord(sourceCodeOntario, null);

    const sourceCodeKingston = "get_tax_filers_kingston"
    const { data: taxFilersKingstonData, isFetching: isFetchingK, isError: isErrorK } = useRecord(sourceCodeKingston, null);

    const [characteristic, setCharacteristic] = React.useState("Median total income of tax filers");
    const [uniqueCharacteristic, setUniqueCharacteristic] = React.useState([]);

    const [location, setLocation] = React.useState(["Kingston", "Ontario"]);

    React.useEffect(() => {
        if (taxFilersOntarioData && taxFilersKingstonData && taxFilersOntarioData.length > 0 && taxFilersKingstonData.length > 0) {
            setUniqueCharacteristic(getUniqueCharacteristic(taxFilersOntarioData[0].data, taxFilersKingstonData[0].data));
        }
    }, [taxFilersOntarioData, taxFilersKingstonData])

    const handleCharacteristicChange = (event) => {
        const {
            target: { value },
        } = event;

        setCharacteristic(
            value,
        );
    };

    const handleLocationChange = (event) => {
        const {
            target: { value },
        } = event;
        setLocation(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <>
            {isErrorO && isErrorK &&
                <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Something went wrong. Please try reloading the page.
                    </Alert>
                </Snackbar>
            }
            {isFetchingO && isFetchingK &&
                <Skeleton variant="rectangular" height={200} />
            }
            {taxFilersOntarioData && taxFilersKingstonData &&
                <>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={12} lg={9}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="characteristic-simple-select-label">Characteristic</InputLabel>
                                <Select
                                    labelId="characteristic-simple-select-label"
                                    id="characteristic-simple-select"
                                    value={characteristic}
                                    label="Characteristic"
                                    onChange={handleCharacteristicChange}
                                >
                                    {uniqueCharacteristic.sort().map((option) => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="location-multiple-checkbox-label">Location</InputLabel>
                                <Select
                                    labelId="location-multiple-checkbox-label"
                                    id="location-multiple-checkbox"
                                    multiple
                                    value={location}
                                    onChange={handleLocationChange}
                                    input={<OutlinedInput label="Location" />}
                                    renderValue={(selected) => selected.sort().join(', ')}
                                >
                                    {["Kingston", "Ontario"].map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox checked={location.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            {taxFilersOntarioData.length > 0 &&
                                <Line data={getChartData({ ...taxFilersOntarioData[0] }, { ...taxFilersKingstonData[0] }, characteristic, location)} options={options} />
                            }
                        </Grid>
                    </Grid>
                    <DataSourceRef code={sourceCodeOntario} />
                    <DataSourceRef code={sourceCodeKingston} />
                </>
            }
        </>
    )
}

export default TaxFilers;
