import React from 'react';
import {
    Snackbar,
    Skeleton,
    Grid
} from '@mui/material';
import { Alert } from '@mui/material';
import DataSourceRef from '../../common/footnotes/DataSourceRef';
import { Bar } from "react-chartjs-2";
import { useRecord } from "../../../api/records";

import onlyUnique from '../../../helpers/dataManipulation';
import palette from '../../../helpers/palette';

const getChartData = (nonGovernmentRevenueData) => {
    let ranges = getUniqueRanges(nonGovernmentRevenueData)
    let years = Object.keys(nonGovernmentRevenueData.data[0]).sort()

    let colourInd = 0
    let chromaPalette = palette(ranges.length)

    let datasets = ranges.map((range) => {

        let data =  years.map((year) => {
            let datapoints = nonGovernmentRevenueData.data
                .filter(obj => obj[year] === range)
                .map(obj => obj[year])
            
            return {
                x: datapoints.length,
                y: year
            }
        })

        let dataset = {
            label: range,
            backgroundColor: chromaPalette[colourInd],
            borderColor: chromaPalette[colourInd],
            data: data,
            grouped: true
        }

        colourInd += 1
        return dataset
    })
    
    return {
        datasets: datasets
    }
}

const options = {
    indexAxis: 'y',
    plugins: {
        legend: {
            display: true,
        },
    },
    scales: {
        x: {
            stacked: false,
            title: {
                text: "Number of Sites that Received a Percentage of Non-Government/Other Funding",
                display: true
            }
        },
        y: {
            stacked: false,
            title: {
                text: "Year",
                display: true
            }
        }
    }
};

function getUniqueRanges(dataset) {
    let ranges = []
    let years = Object.keys(dataset.data[0]).sort()

    for (const year of years) {
        ranges = ranges.concat(dataset.data
            .map(obj => obj[year])
            .filter(obj => obj !== null)
            .filter(obj => obj.includes('%'))
        )
    }
    
    return ranges.filter(onlyUnique).sort((a, b) =>
        parseInt(a) - parseInt(b)
    )
}

const NonGovernmentRevenue = () => {
    const sourceCode = "get_non_government_revenue"
    const { data: nonGovernmentRevenueData, isFetching, isError } = useRecord(sourceCode, null);

    return (
        <>
            {isError &&
                <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Something went wrong. Please try reloading the page.
                    </Alert>
                </Snackbar>
            }
            {isFetching &&
                <Skeleton variant="rectangular" height={200} />
            }
            {nonGovernmentRevenueData &&
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Bar data={getChartData({ ...nonGovernmentRevenueData[0] })} options={options} />
                        </Grid>
                    </Grid>
                    <DataSourceRef code={sourceCode} />
                </>
            }
        </>
    )
}

export default NonGovernmentRevenue;

