import React from 'react';

import {
    Snackbar,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Grid
} from '@mui/material';
import { Alert } from '@mui/material';
import DataSourceRef from '../../common/footnotes/DataSourceRef';

import { Bar } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from '../../../helpers/dataManipulation';
import palette from '../../../helpers/palette';

const getChartData = (enrolmentData, descriptor) => {
    let chromaPalette = palette(descriptor.length)
    let colourInd = 0

    let datasets = descriptor.map(descriptorValue => {
        // narrow to this descriptor
        // return x is academic year & y is grad + undergrad enrolment
        let data_point = enrolmentData.data
            .filter(obj => obj["Descriptor"] === descriptorValue)
            .filter(obj => obj["Study Level"] === "Undergraduate")
            .map(function (obj) {
                let graduate = enrolmentData.data
                    .filter(o => o["Descriptor"] === descriptorValue)
                    .filter(o => o["Study Level"] === "Graduate")
                    .filter(o => o["Academic Year"] === obj["Academic Year"])
                    .map(o => o["Enrolment"])

                // some empty data points are "N/A" and some are "-" but regardless we still want to do the math
                let number_type_check = !Number.isInteger(obj["Enrolment"]) ^ !Number.isInteger(graduate[0])

                // NOTE: we are taking the sum of all the students (graduate and undergraduate) right now
                // we could make them separate (labels "Graduate Degree", "Undergraduate Degree", etc.)
                return {
                    x: obj["Academic Year"],
                    y: number_type_check ? !Number.isInteger(obj["Enrolment"]) ? graduate[0] : obj["Enrolment"] : obj["Enrolment"] + graduate[0]
                }
            })

        let set = {
            label: descriptorValue,
            data: data_point
        }

        return set
    })

    // sorting the datasets
    // 1- drawing longer datasets first (to avoid technical issues, but these should all be the same length)
    // 2- drawing datasets in alphabetical order by label
    datasets.sort(function (a,b) {
        if (a.data.length < b.data.length) {
            return -1
        } else if (a.data.length > b.data.length) {
            return 1
        } else {
            if (a.label <= b.label) {
                return -1
            } else {
                return 1
            }
        }
    })

    // setting the colours for each dataset AFTER we have sorted them
    datasets.forEach(function (item) {
        item["backgroundColor"] = chromaPalette[colourInd]
        item["borderColor"] = chromaPalette[colourInd]
        colourInd += 1
    })

    return {
        datasets: datasets
    }
}

const options = {
    plugins: {
        legend: {
            display: true,
        },
    },
    scales: {
        x: {
            stacked: true,
            title: {
                text: "Academic Year",
                display: true
            }
        },
        y: {
            stacked: true,
            title: {
                text: "Number of Students",
                display: true
            }
        }
    }
};

function getUniqueCategory(data) {
    return data
    .map(obj => obj["Category"])
    .filter(onlyUnique)
    .sort()
}

function getUniqueDescriptor(data, category) {
    return data
    .filter(obj => obj["Category"] === category)
    .map(obj => obj["Descriptor"])
    .filter(onlyUnique)
}

const UniversityEnrolment = () => {
    const sourceCode = "get_enrolment_university"
    const { data: enrolmentData, isFetching, isError } = useRecord(sourceCode, null);

    const [category, setCategory] = React.useState("");
    const [uniqueCategory, setUniqueCategory] = React.useState([])

    const [descriptor, setDescriptor] = React.useState([""]);
    const [uniqueDescriptor, setUniqueDescriptor] = React.useState([])

    React.useEffect(() => {
        if(enrolmentData) {
            let categories = getUniqueCategory(enrolmentData[0].data)
            setUniqueCategory(categories);
            setCategory(categories[0] || "")
            //setUniqueCategory(getUniqueCategory(enrolmentData[0].data));
        }
    }, [enrolmentData])

    React.useEffect(() => {
        if(enrolmentData) {
            let descriptors = getUniqueDescriptor(enrolmentData[0].data, category);
            setUniqueDescriptor(descriptors);
            setDescriptor(descriptors);
        }
    }, [enrolmentData, category])
    
    const handleCategoryChange = (event) => {
        const {
          target: { value },
        } = event;
 
        setCategory(
          value,
        );
    };

    const handleDescriptorChange = (event) => {
        const {
            target: { value },
        } = event;
        setDescriptor(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <>
            {isError && 
                <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Something went wrong. Please try reloading the page.
                    </Alert>
                </Snackbar>
            }
            {isFetching && 
                <Skeleton variant="rectangular" height={200} />
            }
            {enrolmentData &&
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="category-simple-select-label">Category</InputLabel>
                                <Select
                                    labelId="category-simple-select-label"
                                    id="category-simple-select"
                                    value={category}
                                    label="Category"
                                    onChange={handleCategoryChange}
                                >
                                    {uniqueCategory.sort().map((option) => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="descriptor-multiple-checkbox-label">Descriptor</InputLabel>
                                <Select
                                    labelId="descriptor-multiple-checkbox-label"
                                    id="descriptor-multiple-checkbox"
                                    multiple
                                    value={descriptor}
                                    onChange={handleDescriptorChange}
                                    input={<OutlinedInput label="Descriptor" />}
                                    renderValue={(selected) => selected.sort().join(', ')}
                                >
                                    {uniqueDescriptor.sort().map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox checked={descriptor.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Bar data={getChartData({ ...enrolmentData[0] }, descriptor)} options={options} />
                        </Grid>
                    </Grid>
                    <DataSourceRef code={sourceCode} />
                </>
            }
        </>
    )
}

export default UniversityEnrolment;
