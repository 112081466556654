import * as React from 'react';

import {
  Grid,
  Box,
  Tab,
  Tabs
} from '@mui/material';

import DashboardContainer from '../../layout/DashboardContainer';
import DashboardTitle from '../../typography/DashboardTitle';
import DashboardSubtitle from '../../typography/DashboardSubtitle';
import VisualizationTitle from '../../typography/VisualizationTitle';
import VisualizationNarrative from '../../typography/VisualizationNarrative';
import TVCard from '../../cards/TVCard';
import ElementarySecondaryEnrolment from '../../indicators/demographics/ElementarySecondaryEnrolment';
import UniversityEnrolment from '../../indicators/demographics/UniversityEnrolment';
import CollegeEnrolment from '../../indicators/demographics/CollegeEnrolment';
import BroadAgeGroups from '../../indicators/demographics/BroadAgeGroups';
import PopulationPyramid from '../../indicators/demographics/PopulationPyramid';
import ReadMore from '../../cards/ReadMore';
import DataSourceRef from '../../common/footnotes/DataSourceRef';


import imgDemographicsMap from '../../../static/img/demographics/KingstonPopulationChangeMap.pdf';

const Demographics = (props) => {
  const [currTab, setCurrTab] = React.useState(0)
  const mapElemDiv = React.useRef(null);
  const [mapElemDivWidth, setMapElemDivWidth] = React.useState(0);

  React.useLayoutEffect(() => {
    setMapElemDivWidth(mapElemDiv.current.offsetWidth);
  }, []);

  const handleTabChange = (event, newValue) => {
    setCurrTab(newValue);
  };

  const tabArray = [
    {
      label: "Languages",
      src: "https://native-land.ca/api/embed/embed.html?maps=languages"
    }, {
      label: "Territories",
      src: "https://native-land.ca/api/embed/embed.html?maps=territories"
    }, {
      label: "Treaties",
      src: "https://native-land.ca/api/embed/embed.html?maps=treaties"
    }
  ]

  return (
    <DashboardContainer sx={{ mt: 7 }}>
      <DashboardTitle sx={{ mb: 3 }}>{Demographics.title}</DashboardTitle>
      {/* <VisualizationNarrative variant="body1" sx={{ mb: 2 }}>
        History and innovation thrive in Kingston, as a dynamic city located along the beautiful shores of Lake Ontario, an easy drive from Toronto, Ottawa and Montreal, in the heart of eastern Ontario. With a stable and diversified economy that includes global corporations, innovative startups and all levels of government, Kingston's high quality-of-life offers access to world-class education and research institutions, advanced healthcare facilities and vibrant entertainment and tourism activities.
      </VisualizationNarrative> */}
      <VisualizationNarrative variant="body1" sx={{ mb: 2 }}>
        To better understand the unique and diverse communities that make up and surround Kingston, historic and current demographic data allows residents, planners, researchers and others to learn more about the neighbourhoods and communities we all live, work and play in.
      </VisualizationNarrative>
      <VisualizationNarrative variant="body1" sx={{ mb: 2 }}>
        The City of Kingston acknowledges that we are on the traditional homeland of the Anishinaabe, Haudenosaunee and the Huron-Wendat, and thank these nations for their care and stewardship over this shared land. Today, the city is committed to working with Indigenous peoples and all residents to pursue a united path of reconciliation.
      </VisualizationNarrative>

      <Grid container spacing={7} sx={{ mb: 7 }}>

        <Grid item xs={12} lg={12}>

          <Grid item xs={12} lg={12}>
            <VisualizationTitle sx={{ mt: 4, mb: 1 }}>Native Land Map</VisualizationTitle>
            <VisualizationNarrative sx={{ mb: 1 }}>
              The following map from native-land.ca is the result of a project by Native Land Digital, an Indigenous governed non-profit organization. The map is a comprehensive effort by the Native Land Digital team to describe and show Indigenous traditional territories, languages, and treaties across the world. When consulting the map of for information about the land that is now known as Kingston, the native-land.ca map notes that the area is the traditional territory of the Wendake, Haudenosaunee, and Mississauga peoples. The Native Land Digital map creates a space where “non-Indigenous people can be invited and challenged to learn more about the lands they inhabit, the history of those lands, and how to actively be part of a better future going forward together.
            </VisualizationNarrative>
            <Box sx={{ maxWidth: { xs: 320, sm: 700, md: '100%' }, borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={currTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons='auto'
              >
                {tabArray.map((tabElem, index) => {
                  return <Tab key={"tab-item-" + index} label={tabElem.label} />
                })}
              </Tabs>
            </Box>
            <Box ref={mapElemDiv} sx={{ height: 375, width: '100%' }}>
              {tabArray.map((tabElem, index) => {
                return (
                  <iframe
                    key={"native-land-map-" + index}
                    title={tabElem.label}
                    src={tabElem.src}
                    width={mapElemDivWidth}
                    height='375'
                    style={{
                      position: 'absolute',
                      opacity: index === currTab ? 1 : 0,
                      zIndex: index === currTab ? 100 : 0,
                    }}
                  />

                )
              })}

            </Box>
            <DataSourceRef code="get_kingston_native_land_map" />
          </Grid>

          <Grid item xs={12} lg={12}>

            <VisualizationNarrative sx={{ mt: 2, mb: 1 }}>
              Once every five years, Statistics Canada provides a detailed and comprehensive demographic portrait of Canada through the Census of Population. By better understanding the social, economic and demographic characteristics of populations, this data can support neighbourhood-based planning, which is essential to building strong, healthy and resilient communities.
            </VisualizationNarrative>
            <VisualizationNarrative sx={{ mb: 1 }}>
              <ReadMore>
                The latest census in Canada was completed on May 11, 2021.  Individuals were counted in the census by their “usual place of residence”, which is defined as the dwelling in which a person lives most of the time.  More information about definitions and data released through the census can be found on the Statistics Canada website.
              </ReadMore>
            </VisualizationNarrative>

            <VisualizationTitle sx={{ mt: 7, mb: 1 }}>Kingston Census Metropolitan Area (CMA) Population Change from 2016 to 2021</VisualizationTitle>
            <VisualizationNarrative sx={{ mb: 4 }}>
              The 2021 population of Kingston CMA was 172,546, which grew by 11,371 or 7.1% since 2016.  Kingston CMA was among the top 10 mid-sized CMAs that experienced the largest growth rates in Ontario between 2016 and 2021.
            </VisualizationNarrative>
            <iframe src={imgDemographicsMap} title='Kingston CMA Population Map' width='100%' height='1100' ></iframe>
            <DataSourceRef code="kingston_cma" />
          </Grid>

          <Grid item xs={12} lg={12}>
            <VisualizationTitle sx={{ mt: 7, mb: 1 }}>City of Kingston Population by Age and Gender</VisualizationTitle>
            <VisualizationNarrative sx={{ mt: 1, mb: 1 }}>
              As the total population of Kingston continues to grow, the age structure continues to shift. The highest growth continues to be in the age group over 65 years.  This group, representing the baby boom generation, saw an increase of 18% between 2016 and 2021 and represents 21% of the total population.
            </VisualizationNarrative>
            <VisualizationNarrative sx={{ mt: 1, mb: 4 }}>
              <ReadMore>
                In 2021, Kingston population was 51% female and 49% male, with the ratio changing with age.  Younger age categories reported more males than females, up to the age of 45 to 49 where the trend switches. The ratio of males to females significantly declines over the age of 85 where the proportion of females is 62% of the population.
              </ReadMore>
            </VisualizationNarrative>
            <PopulationPyramid />
          </Grid>

          <Grid item xs={12} lg={12}>
            <VisualizationTitle sx={{ mt: 7, mb: 1 }}>Broad Age Groups</VisualizationTitle>
            <VisualizationNarrative sx={{ mb: 1 }}>
              The City of Kingston represents the largest population within the Kingston CMA region, with 132,485 in 2021.  There was significant growth in the population over the age of 65 across the region as the baby boom generation continues to age.  This is creating a record number of individuals nearing retirement and creating increase pressure on the labour market.
            </VisualizationNarrative>
            <VisualizationNarrative sx={{ mb: 4 }}>
              <ReadMore>
                Within the city, there is a large population of youth aged 15 to 30 in the region. Although census population counts record the usual residence for post-secondary students, which is usually that of their parents if they return to live with them during any part of the year, they may or may not be included in the population counts for the City of Kingston.  More information on who is counted in the census is available on the City of Kingston website.
              </ReadMore>
            </VisualizationNarrative>

            <BroadAgeGroups sx={{ mt: 4 }} />
          </Grid>

          <Grid item xs={12} lg={12}>
            <VisualizationTitle sx={{ mt: 7, mb: 1 }}>Elementary School &amp; Secondary Enrolment</VisualizationTitle>
            <VisualizationNarrative sx={{ mb: 1 }}>
              Education is strongly associated with long-term health and quality of life, and for many it is a pathway out of poverty.  As one of the top Sustainable Development Goals, the United Nations identifies quality education as enabling upward socioeconomic  mobility and a key indicator in escaping poverty.
            </VisualizationNarrative>
            <VisualizationNarrative sx={{ mb: 4 }}>
              <ReadMore>
                During the pandemic, the disruption in learning and education was unprecedented with the closure of schools and in-person learning.  As we are still looking to comprehend the impact and consequences of this disruption in learning, especially for the most vulnerable and marginalized communities, the local population of children enrolled in school provides the data to help understand the scope of this impact and support the planning of future needs.
              </ReadMore>
            </VisualizationNarrative>

            <ElementarySecondaryEnrolment />
          </Grid>

          <Grid>
            <VisualizationTitle sx={{ mt: 7, mb: 1 }}>Post-Secondary Enrolment </VisualizationTitle>
            <VisualizationNarrative sx={{ mb: 1 }}>
              Post-secondary attainment is an important indicator of the knowledge, skills and competencies that allow people to participate effectively in society and the economy.  Individuals with higher levels of formal education are less likely to become unemployed and have the potential to earn more income throughout their lifetime.
            </VisualizationNarrative>
            <VisualizationNarrative sx={{ mb: 1 }}>
              <ReadMore>
                <VisualizationNarrative sx={{ mb: 1 }}>
                  The City of Kingston is home to two universities, Queens University and the Royal Military College, and one college, St. Lawrence College.  Along with tourism, these educational institutions and the students they attract, contribute a significant amount to the city’s local economy.
                </VisualizationNarrative>
                <VisualizationNarrative sx={{ mb: 1 }}>
                  The data below outlines key aggregated enrolment data for post-secondary students from Queen’s University and St. Lawrence College gathered through the Ministry of Advanced Education and Skills Development reporting system.
                </VisualizationNarrative>
              </ReadMore>
            </VisualizationNarrative>

            <Grid item xs={12} lg={12}>
              <VisualizationTitle sx={{ mt: 7, mb: 1 }}>Queen's University Enrolment</VisualizationTitle>
              <VisualizationNarrative sx={{ mb: 4 }}>Enrolment in Kingston universities (Queen's University)</VisualizationNarrative>
              <UniversityEnrolment />
            </Grid>

            <Grid item xs={12} lg={12}>
              <VisualizationTitle sx={{ mt: 7, mb: 1 }}>St. Lawrence College Enrolment</VisualizationTitle>
              <VisualizationNarrative sx={{ mb: 4 }}>Enrolment in Kingston Colleges (St. Lawrence College)</VisualizationNarrative>
              <CollegeEnrolment />
            </Grid>
          </Grid>


          <VisualizationTitle sx={{ mt: 7, mb: 1 }}>Top Comsumer Value in Kingston</VisualizationTitle>
          <VisualizationNarrative>
            Based on recent consumer data, three of the top values seen by Kingston consumers include the attraction to nature, practical and purposeful buying, and the ethical consumption of products and services.
          </VisualizationNarrative>

          <ReadMore>
            <h3> #1 – Attraction to Nature</h3>
            <VisualizationNarrative>
              Given that Kingston and the surrounding area resides on naturally marshy and fertile land right along Lake Ontario, there is a close connection to both productive earth and bodies of water. As a small-medium city with many historic designations, residents and visitors are exposed to a less metropolitan urban experience – ultimately connecting individuals more to nature-based landscape and activities, which have psychological health benefits.
            </VisualizationNarrative>
            <h3> #2 – Utilitarian Consumerism</h3>
            <VisualizationNarrative>
              This value reflects residents’ purposeful purchasing. Due to many socio-economic factors in Kingston, individuals may be looking to purchase items that are practical and purposeful rather than based on aesthetics.  This may be due to the wide range of disposable incomes in Kingston, a large skilled labour force and those purchasing/investing in specific work-related items, and the preference to purchase items that reduce the impact on the environment.
            </VisualizationNarrative>
            <h3> #3 – Ethical Consumerism</h3>
            <VisualizationNarrative>
              This value reflects the willingness to base consumer decisions on the perceived ethics of the company making the product or providing the service.  The desire to see companies be good corporate citizens is a top value in Kingston may come from the range of incomes and buying power across Kingston, as well as the educational attainment, connection to post-secondary education institutions with high numbers post-secondary students supporting the local economy and the attraction to nature.
            </VisualizationNarrative>

          </ReadMore>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4} sx={{ mb: 4 }}>
              <VisualizationNarrative sx={{ mb: 4 }}></VisualizationNarrative>
              <TVCard rank='1' title='Attraction to Nature' description='How close people want to be to nature, whether to recharge their spiritual batteries or to enjoy a simpler, healthier or more authentic way of life. ' index='118' />
            </Grid>
            <Grid item xs={12} lg={4} sx={{ mb: 4 }}>
              <VisualizationNarrative sx={{ mb: 4 }}></VisualizationNarrative>
              <TVCard rank='2' title='Utiltarian Consumerism' description='Tendency to base purchase decisions on utilitarian rather than aesthetic considerations. Measures the attention given to utility of objects and products purchased. People strong on this construct seldom buy products purely for their appearance. Lack of a need to engage in personal expression through aesthetic means. ' index='115' />
            </Grid>
            <Grid item xs={12} lg={4} sx={{ mb: 4 }}>
              <VisualizationNarrative sx={{ mb: 4 }}></VisualizationNarrative>
              <TVCard rank='3' title='Ethical Consumerism' description='Willingness to base consumer decisions on the perceived ethics of the company making the product (e.g., whether management treats employees fairly, co-operates with governments that do not meet ethical standards, or uses testing methods that involve mistreatment of animals). Desire to see companies be good corporate citizens in terms of these new social concerns.' index='112' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </DashboardContainer>
  )
}

export default Demographics;

Demographics.path = "/dashboards/demographics"
Demographics.title = "Demographics"
Demographics.description = "This dashboard shows a variety of indicators related to demographics of the Kingston area and Canadian populations."