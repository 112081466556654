import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { history } from "./helpers/history";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import Navbar from "./components/common/Navbar";
import Dashboards from "./components/pages/Dashboards";
import Employment from "./components/pages/dashboards/Employment";
import BusinessResilience from "./components/pages/dashboards/BusinessResilience";
import DataDictionary from "./components/pages/DataDictionary"
import Timeline from "./components/pages/Timeline";
import Art from "./components/pages/Art";
import CommunityVulnerabilities from "./components/pages/dashboards/CommunityVulnerabilities";
import Housing from "./components/pages/dashboards/Housing";
import EnvironmentSustainability from "./components/pages/dashboards/EnvironmentSustainability";
import Mobility from "./components/pages/dashboards/Mobility";
import CulturalHeritage from "./components/pages/dashboards/CulturalHeritage";
import React from "react";
import Home from "./components/pages/Home";
import Demographics from "./components/pages/dashboards/Demographics";


const mdTheme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#012A4A',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    }
  }

});

const routeComponents = [
  Home,
  Dashboards,
  DataDictionary,
  Timeline,
  Art,
  Employment,
  BusinessResilience,
  CommunityVulnerabilities,
  Housing,
  EnvironmentSustainability,
  Mobility,
  CulturalHeritage,
  Demographics
]

function App() {
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <div className="App">
        <Box sx={{ display: 'flex' }}>
          <Navbar>
            <BrowserRouter history={history}>
              <Routes>

                {routeComponents.map(component => {
                  return <Route key={component.title} path={component.path} element={React.createElement(component)} />
                })}

                <Route
                  path="*"
                  element={<Navigate to={Home.path} />}
                />
              </Routes>
            </BrowserRouter>
          </Navbar>
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;
